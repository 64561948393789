import React, { useState } from "react";
import NavBar from "./Component/NavBar/NavBar";
import LocationModal from "./Component/NavBar/LocationModal";
import FirstSection from "./Component/Section/FirstSection/FirstSection";
import SecondSection from "./Component/Section/SecondSection/SecondSection";
import style from "./App.module.css";
import ThirdSection from "./Component/Section/ThirdSection/ThirdSection";
import FourthSection from "./Component/Section/FourthSection/FourthSection";
import FifthSection from "./Component/Section/FifthSection/FifthSection";
import SectionSixth from "./Component/Section/SectionSixth/SectionSixth";
import OrderSummary from "./Component/OrderSummary/OrderSummary";
import OrderSummaryModal from "./Component/OrderSummaryModal/OrderSummaryModal";
import { Helmet } from "react-helmet";
import Footer from "./Component/Footer/Footer";
import BestSellers from "./Component/Section/BestSellers/BestSellers";
import BestDeals from "./Component/Section/BestDeals/BestDeals";
import Combos from "./Component/Section/Combos/Combos";
import PopularSearches from "./Component/PopularSearches/PopularSearches";

const App = () => {
  const [locationSelected, setLocationSelected] = useState(false);
  const [location, setLocation] = useState("");

  const handleSelectLocation = (selectedLocation) => {
    setLocation(selectedLocation);
    setLocationSelected(true);
  };

  return (
    <div className={style.main}>

      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <title>zuluresh | Buy Fresh & High Quality Meat at Best Price on Zuluresh</title>
        <meta
          name="description"
          content="Order meat online in just a few clicks.Zuluresh offers 100% fresh & best quality Chicken, Fish/Prawns and Mutton products online."
        />
        <link rel="canonical" href="https://www.zuluresh.com/" />
        <meta name="title" content="zuluresh" />
        <meta name="keyword" content="zuluresh | Buy Fresh & High Quality Meat at Best Price on Zuluresh" />
        {/* Schema Markup */}
        <script type="application/ld+json">
          {`
          {
            "@context": "http://schema.org",
            "@type": "WebSite",
            "name": "zuluresh",
            "url": "https://www.zuluresh.com/",
            "description": "Order meat online in just a few clicks.Zuluresh offers 100% fresh & best quality Chicken, Fish/Prawns and Mutton products online.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": "https://www.zuluresh.com/",
              "query-input": "required name=search_term_string"
            }
          }
          `}
        </script>
        {/* End Schema Markup */}
        {/* Google tag (gtag.js) */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-SJPR5MYB8S"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-SJPR5MYB8S');
          `}
        </script>
    {/* Meta Pixel Code */}
    <script>
      {`
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '2583408375194861');
      fbq('track', 'PageView');
      `}
    </script>
    <noscript>
      {`
      <img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=2583408375194861&ev=PageView&noscript=1"/>
      `}
    </noscript>
    {/* End Meta Pixel Code */}
    {/* Hotjar Tracking Code for https://www.zuluresh.com/ */}
    <script>
      {`
      (function (h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: 5054101, hjsv: 6 };
        a = o.getElementsByTagName("head")[0];
        r = o.createElement("script");
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
      `}
    </script>
      </Helmet>
      <NavBar
        location={location}
        onOpenLocationModal={() => setLocationSelected(false)}
      />
      <LocationModal
        open={!locationSelected}
        onSelectLocation={handleSelectLocation}
      />
         <h1 hidden>zuluresh</h1>
      <OrderSummary />
      <FirstSection />
      <SecondSection />
      <div className={style.page_container}>
      <BestDeals />
      {/* <ThirdSection /> */}
      <BestSellers />
      {/* <FourthSection /> */}
      <Combos />
      {/* <FifthSection /> */}
      <SectionSixth />
      </div>

      <Footer />
      <PopularSearches />
    </div>
  );
};

export default App;
