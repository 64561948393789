import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Tabs,
  Tab,
  Button,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./OrderSummaryModal.module.css";
import { format, addDays } from "date-fns";
import { getTimeSlot } from "../../Api/Api";

const OrderSummaryModal = ({ open, onClose }) => {
  const [tabValue, setTabValue] = useState(0);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [filteredTimeSlots, setFilteredTimeSlots] = useState({
    today: [],
    tomorrow: [],
    dayAfterTomorrow: [],
  });

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSlotClick = (slot) => {
    setSelectedSlot(slot);
    sessionStorage.setItem("selectedSlot", JSON.stringify(slot));
    handleClose();
    window.location.reload()
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    handleAllTimeSlot();
  }, []);

  const handleAllTimeSlot = async () => {
    try{
      const response = await getTimeSlot()
 
      const formatDate = (date) => format(date, "EEEE (dd MMM)");
  
      const today = formatDate(new Date());
      const tomorrow = formatDate(addDays(new Date(), 1));
      const dayAfterTomorrow = formatDate(addDays(new Date(), 2));
  
      const filteredSlots = {
        today: filterSlots(response.timeSlots, today),
        tomorrow: filterSlots(response.timeSlots, tomorrow),
        dayAfterTomorrow: filterSlots(response.timeSlots, dayAfterTomorrow),
      };
  
      setFilteredTimeSlots(filteredSlots);
    }catch{

    }

  };

  const filterSlots = (slots, day) => {
    return slots.filter((slot) => slot.day.includes(day));
  };

  const renderTimeSlots = (slots) => {
    return slots.length > 0 ? (
      slots.map((slot) => (
        <Button
          key={slot._id}
          variant="outlined"
          className={`${styles.slotButton} ${
            selectedSlot === slot._id ? styles.selectedSlot : ""
          }`}
          onClick={() => handleSlotClick(slot)}
        >
          {`${slot.startTime} - ${slot.endTime}`}
        </Button>
      ))
    ) : (
      <Typography>No slots available</Typography>
    );
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className={styles.modalBox}>
        <div className={styles.header}>
          <Typography variant="h6">Order Summary</Typography>
          <IconButton onClick={handleClose} className={styles.closeButton}>
            <CloseIcon />
          </IconButton>
        </div>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab className={styles.tabs} label="Today" />
          <Tab className={styles.tabs} label="Tomorrow" />
          <Tab className={styles.tabs} label="Day After Tomorrow" />
        </Tabs>
        <div className={styles.tabPanel}>
          {tabValue === 0 && renderTimeSlots(filteredTimeSlots.today)}
          {tabValue === 1 && renderTimeSlots(filteredTimeSlots.tomorrow)}
          {tabValue === 2 && renderTimeSlots(filteredTimeSlots.dayAfterTomorrow)}
        </div>
      </Box>
    </Modal>
  );
};

export default OrderSummaryModal;
