import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import styles from "./OrderDetails.module.css";
import NavBar from "../../NavBar/NavBar";
import Footer from "../../Footer/Footer";
import { Divider } from "@mui/material";

const OrderDetails = () => {
  const { orderId } = useParams();
  const [orderData, setOrderData] = useState(null);
  const [loading,setLoading] = useState(false)

  useEffect(() => {
    const fetchOrderData = async () => {
      setLoading(true)
      // Function to retrieve token from cookies
      function getToken() {
        return document.cookie.replace(
          /(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/,
          "$1"
        );
      }

      // Retrieve token
      const token = getToken();
      try {
        const headers = {
          "x-auth-token": token, // Pass the token in the header
          "Content-Type": "application/json", // Set content type to JSON
        };
        const response = await axios.get(
          `https://www.backend.zuluresh.com/user/order/getOrder/${orderId}`,
          { headers }
        );
        setOrderData(response?.data?.orderData);
        setLoading(false)
      } catch (error) {
        console.error("Error fetching the order data", error);
        setLoading(false)
      }
    };

    fetchOrderData();
  }, [orderId]);


  return (
    <div className={styles.main}>
      <NavBar />
      <br />
      {loading && <p>loading..</p>}
      <h1 className={styles.title}>Order Details</h1>
      <div className={styles.container}>
        <div className={styles.orderHeader}>
          <span className={styles.orderId}># {orderData?.orderId}</span>
          <span className={styles.orderStatus}>{orderData?.Order_Status}</span>
        </div>
        <div className={styles.orderInfo}>
          <p>{orderData?.address?.deliverySlot}</p>
          <p>Delivered To</p>
          <p>{`${orderData?.address?.houseNo}, ${orderData?.address?.block}, ${orderData?.address?.street},`}</p>
          <p>{`${orderData?.address?.locality}, ${orderData?.address?.Landmark}, ${orderData?.address?.pincode}`}</p>
          <p>Payment Method</p>
          <p>
            {orderData?.PaymentMethod?.cod
              ? "Cash On Delivery"
              : "Online Payment"}
          </p>
        </div>
        <div className={styles.productDetails}>
          {orderData?.ProductDetails?.map((product, index) => (
            <div key={index} className={styles.product}>
              <div className={styles.left_section_box}>
              <img  src={product?.ProductImg} alt={product?.Product_title} title={product?.Product_title} height={30} width={40} loading="lazy"/>
              <div className={styles.product_name}>
                <span>{`${product?.Product_title} (${product?.Product_unit} ${product?.Product_measureUnit})`}</span>
                <div>
                <span>{`₹ ${product?.Product_price}`} </span>
                <span>x </span>
                <span>{`${product?.Product_quantity} Qty`}</span>
                </div>
           
                <div>
              </div>

     
        
              </div>
              </div>
              <span>{`₹ ${product?.Product_total}`}</span>
            </div>
          ))}
        </div>
        <div className={styles.priceDetails}>
          <p>
            MRP Total: <span>₹ {orderData?.mrpTotal}</span>
          </p>
          <p>
            Discount: <span>₹ {orderData?.Discount}</span>
          </p>
          {orderData?.PromoDiscount && (
            <p>
              Promo Discountl: <span>₹ {orderData?.PromoDiscount}</span>
            </p>
          )}
          <p>
            SubTotal: <span>₹ {orderData?.subTotal}</span>
          </p>
          <p>
            Shipping: <span>₹ {orderData?.Shipping == 0 ? "free" : orderData?.Shipping}</span>
          </p>
          <Divider style={{ backgroundColor: "black", marginTop: "10px" }} />
          <br />
          <p className={styles.totalPrice}>
            Total Price: <span>₹ {orderData?.totalPrice}</span>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default OrderDetails;
