import React from "react";
import AccordionComponent from "../../AccordionComponent/AccordionComponent";
import NavBar from "../../NavBar/NavBar";
import styles from "./FAQs.module.css";
import Footer from "../../Footer/Footer";
import { Helmet } from "react-helmet";

function FAQs() {
  return (
    <>
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <title>zuluresh | Buy Fresh & High Quality Meat at Best Price on Zuluresh</title>
        <meta
          name="description"
          content="Order meat online in just a few clicks.Zuluresh offers 100% fresh & best quality Chicken, Fish/Prawns and Mutton products online."
        />
        <link rel="canonical" href="https://www.zuluresh.com/faqs" />
        <meta name="title" content="zuluresh" />
        <meta name="keyword" content="zuluresh | Buy Fresh & High Quality Meat at Best Price on Zuluresh" />
        {/* Schema Markup */}
        <script type="application/ld+json">
          {`
          {
            "@context": "http://schema.org",
            "@type": "WebSite",
            "name": "zuluresh",
            "url": "https://www.zuluresh.com/faqs",
            "description": "Order meat online in just a few clicks.Zuluresh offers 100% fresh & best quality Chicken, Fish/Prawns and Mutton products online.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": "https://www.zuluresh.com/faqs",
              "query-input": "required name=faqs"
            }
          }
          `}
        </script>
        {/* End Schema Markup */}
        {/* Google tag (gtag.js) */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-SJPR5MYB8S"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-SJPR5MYB8S');
          `}
        </script>
      {/* Meta Pixel Code */}
      <script>
      {`
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '2583408375194861');
      fbq('track', 'PageView');
      `}
    </script>
    <noscript>
      {`
      <img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=2583408375194861&ev=PageView&noscript=1"/>
      `}
    </noscript>
    {/* End Meta Pixel Code */}
    {/* Hotjar Tracking Code for https://www.zuluresh.com/ */}
    <script>
      {`
      (function (h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: 5054101, hjsv: 6 };
        a = o.getElementsByTagName("head")[0];
        r = o.createElement("script");
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
      `}
    </script>
      </Helmet>
      <NavBar />
      <div className={styles.main}>
        <h1>FAQs</h1>
        <div className={styles.container}>
        <AccordionComponent />
        </div>

        <Footer />
      </div>
    </>
  );
}

export default FAQs;
