import React, { useEffect, useState } from "react";
import styles from "./AllProducts.module.css";
import NavBar from "../../NavBar/NavBar";
import ProductCard from "../../ProductCard/ProductCard";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import axios from "axios";
import OrderSummary from "../../OrderSummary/OrderSummary";
import Footer from "../../Footer/Footer";

function AllProducts() {
  const { setas } = useParams();
  const [products, setProducts] = useState([]);

  const formatTitle = (title) => {
    return title
      .split("-") // Split the string by hyphens
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(" "); // Join the words with spaces
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          `https://www.backend.zuluresh.com/admin/product/getAllproduct?setAs=${formatTitle(
            setas
          )}`
        );
        if (response.data.status) {
          setProducts(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, [setas]);

  return (
    <>
      <OrderSummary />
      <Helmet>
    <html lang="en" />
    <meta charSet="utf-8" />
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    <title>zuluresh | Buy Fresh & High Quality Meat at Best Price on Zuluresh</title>
    <meta
      name="description"
      content="Order meat online in just a few clicks.Zuluresh offers 100% fresh & best quality Chicken, Fish/Prawns and Mutton products online."
    />
    <link
      rel="canonical"
      href={`https://www.zuluresh.com/all-products/${setas}`}
    />
    <meta name="title" content="zuluresh" />
    <meta
      name="keyword"
      content="zuluresh | Buy Fresh & High Quality Meat at Best Price on Zuluresh"
    />
    {/* Schema Markup */}
    <script type="application/ld+json">
      {JSON.stringify({
        "@context": "http://schema.org",
        "@type": "WebSite",
        "name": "zuluresh",
        "url": `https://www.zuluresh.com/all-products/${setas}`,
        "description": "Order meat online in just a few clicks.Zuluresh offers 100% fresh & best quality Chicken, Fish/Prawns and Mutton products online.",
        "potentialAction": {
          "@type": "SearchAction",
          "target": `https://www.zuluresh.com/all-products/${setas}`,
          "query-input": `required name=${setas}`
        }
      })}
    </script>
    {/* End Schema Markup */}
    {/* Google tag (gtag.js) */}
    <script async src="https://www.googletagmanager.com/gtag/js?id=G-SJPR5MYB8S"></script>
    <script>
      {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'G-SJPR5MYB8S');
      `}
    </script>
    {/* Meta Pixel Code */}
    <script>
      {`
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '2583408375194861');
      fbq('track', 'PageView');
      `}
    </script>
    <noscript>
      {`
      <img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=2583408375194861&ev=PageView&noscript=1"/>
      `}
    </noscript>
    {/* End Meta Pixel Code */}
    {/* Hotjar Tracking Code for https://www.zuluresh.com/ */}
    <script>
      {`
      (function (h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: 5054101, hjsv: 6 };
        a = o.getElementsByTagName("head")[0];
        r = o.createElement("script");
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
      `}
    </script>
  </Helmet>
      <NavBar />
      <div className={styles.main}>
        <h1 style={{ color: "#941a49" }}>{formatTitle(setas)}</h1>
        <div className={styles.container}>
          {products.length ? (
            products.map((product, index) => (
              <div className={styles.slider} key={index}>
                <ProductCard
                  stock={product.Stock}
                  measureunit={product.measureUnit}
                  unit={product.unit}
                  image={product.productImg[0]?.url}
                  name={product.title}
                  originalPrice={product.MRP}
                  discount={product.discount}
                  price={product.price}
                  specialOffer={product.specialOffer}
                  id={product._id}
                />
              </div>
            ))
          ) : (
            <p>No products found.</p>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AllProducts;
