import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AssignmentIcon from "@mui/icons-material/Assignment";
import HomeIcon from "@mui/icons-material/Home";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ContactsIcon from "@mui/icons-material/Contacts";
import GavelIcon from "@mui/icons-material/Gavel";
import HelpIcon from "@mui/icons-material/Help";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import LogoutIcon from "@mui/icons-material/Logout";
import Cookies from "js-cookie";

// Function to get token from cookies
function getToken() {
  return document.cookie.replace(
    /(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/,
    "$1"
  );
}
const token = getToken();

const handleLogout = () => {
  Cookies.remove("token");
  sessionStorage.clear();
  window.location.href = "/";
};

const DrawerList = ({ toggleDrawer }) => (
  <Box
    sx={{ width: 250 }}
    role="presentation"
    onClick={toggleDrawer(false)}
    onKeyDown={toggleDrawer(false)}
  >
    <Box display="flex" justifyContent="flex-end" p={2}>
      <IconButton onClick={toggleDrawer(false)}>
        <CloseIcon />
      </IconButton>
    </Box>
    <Divider />
    <List>
      <ListItem
        button
        onClick={() => (window.location.href = "/update-profile")}
      >
        <ListItemIcon>
          <AccountCircleIcon />
        </ListItemIcon>
        <ListItemText primary="My Profile" />
      </ListItem>
      <Divider />
      <ListItem button onClick={() => (window.location.href = "/all-orders")}>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Orders" />
      </ListItem>
      <Divider />
      <ListItem button onClick={() => (window.location.href = "/add-address")}>
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary="Address" />
      </ListItem>
      <Divider />
      {/* <ListItem
        button
        onClick={() => (window.location.href = "/notifications")}
      >
        <ListItemIcon>
          <NotificationsIcon />
        </ListItemIcon>
        <ListItemText primary="Notifications" />
      </ListItem>
      <Divider /> */}
      {/* <ListItem button onClick={() => (window.location.href = "/Contacts")}>
        <ListItemIcon>
          <ContactsIcon />
        </ListItemIcon>
        <ListItemText primary="Contacts" />
      </ListItem> */}
      <Divider />
      <ListItem
        button
        onClick={() => (window.location.href = "/terms-and-conditions")}
      >
        <ListItemIcon>
          <GavelIcon />
        </ListItemIcon>
        <ListItemText primary="Terms and Conditions" />
      </ListItem>
      <Divider />
      <ListItem button onClick={() => (window.location.href = "/faqs")}>
        <ListItemIcon>
          <HelpIcon />
        </ListItemIcon>
        <ListItemText primary="Faqs" />
      </ListItem>
      <Divider />
      <ListItem
        button
        onClick={() => (window.location.href = "/privacy-policy")}
      >
        <ListItemIcon>
          <PrivacyTipIcon />
        </ListItemIcon>
        <ListItemText primary="Privacy Policy" />
      </ListItem>
      <Divider />
      {token ? (
        <ListItem button onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      ) : (
        <ListItem button onClick={() => (window.location.href = "/login")}>
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
          <ListItemText primary="Login" />
        </ListItem>
      )}
      <Divider />
    </List>
  </Box>
);

export default DrawerList;
