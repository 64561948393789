import React, { useState, useEffect, useRef } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import MenuIcon from "@mui/icons-material/Menu";
import Cookies from "js-cookie";
import {
  Box,
  TextField,
  InputAdornment,
  Popover,
  List,
  ListItem,
  ListItemText,
  Drawer,
} from "@mui/material";
import logo from "../Images/zuluresh logo.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import style from "./NavBar.module.css";
import LocationModal from "./LocationModal"; // Import the LocationModal component
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { AddtoCart, getAllCategory, getCheckoutData } from "../../Api/Api";
import axios from "axios"; // Import Axios
import { updateCart } from "../Recoil/Recoil";
import { useRecoilState } from "recoil";
import DrawerList from "./DrawerList"; // Import the DrawerList component
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const NavBar = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [categoriesAnchorEl, setCategoriesAnchorEl] = useState(null);
  const [accountAnchorEl, setAccountAnchorEl] = useState(null);
  const [placeholder, setPlaceholder] = useState("Search here");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [address, setAddress] = useState("Select");
  const [locationModalOpen, setLocationModalOpen] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(
    JSON.parse(sessionStorage.getItem("totalprice")) || 0
  );
  const [udpateCart, setUpdateCart] = useRecoilState(updateCart);
  const [billDetails, setBillingDetails] = useState([]);
  const searchBoxRef = useRef(null);
  const [isInputNotEmpty, setIsInputNotEmpty] = useState(false);
  const handleAddToCartInBeckend = async (id) => {
    try {
      const response = await AddtoCart(id);
    } catch (error) {
      // console.log(error);
    }
  };

  // Handle add to cart
  const handleAddToCart = (data) => {
    function getToken() {
      return document.cookie.replace(
        /(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/,
        "$1"
      );
    }

    // Retrieve token
    const token = getToken();
    if (token) {
      handleAddToCartInBeckend(data._id);
    }
    // Get the current cart from session storage or initialize an empty array
    const cart = JSON.parse(sessionStorage.getItem("cart")) || [];

    // Check if the product is already in the cart
    const productIndex = cart.findIndex((product) => product.id === data._id);

    if (productIndex !== -1) {
      // Product is already in the cart, increase the quantity
      cart[productIndex].quantity += 1;
    } else {
      // Product is not in the cart, add it with quantity 1
      cart.push({
        id: data._id,
        name: data.title,
        image: data.productImg[0].url,
        originalPrice: data.MRP,
        discount: data.discount,
        price: data.price,
        measureunit: data.measureUnit,
        unit: data.unit,
        quantity: 1,
      });
    }

    // Save the updated cart back to session storage
    sessionStorage.setItem("cart", JSON.stringify(cart));
    setUpdateCart(udpateCart + 3);
  };

  useEffect(() => {
    handleCheckout();
    const totalPrice = JSON.parse(sessionStorage.getItem("totalprice")) || 0;
    setTotalPrice(totalPrice);
    const cart = JSON.parse(sessionStorage.getItem("cart")) || [];
    setCartItems(cart);
    const savedLocation = JSON.parse(
      sessionStorage.getItem("selectedLocation")
    );
    if (savedLocation) {
      setAddress(savedLocation);
      setLocationModalOpen(false);
    }
  }, [udpateCart, totalPrice]);

  const [allcategory, setAllCategory] = useState([]);

  function getToken() {
    return document.cookie.replace(
      /(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/,
      "$1"
    );
  }

  // Retrieve token
  const token = getToken();

  useEffect(() => {
    handleAllCategory();
  }, []);

  // Function to handle checkout data fetching
  const handleCheckout = async () => {
    try {
      const response = await getCheckoutData();
      setBillingDetails(response.data);
    } catch (error) {
      console.error("Error fetching checkout data:", error);
    } finally {
    }
  };

  const handleAllCategory = async () => {
    try {
      const response = await getAllCategory();
      if (response.status) {
        setAllCategory(response.data);
      }
      // console.log(response.data, "response aa raha");
    } catch (error) {
      // console.log(error);
    }
  };
  const handleCategoriesClick = (event) => {
    setCategoriesAnchorEl(event.currentTarget);
  };

  const handleCategoriesClose = () => {
    setCategoriesAnchorEl(null);
  };

  const handleAccountClick = (event) => {
    setAccountAnchorEl(event.currentTarget);
  };

  const handleAccountClose = () => {
    setAccountAnchorEl(null);
  };

  const fetchSearchResults = async (query) => {
    try {
      // Make an API call to fetch products
      const response = await axios.get(
        `https://www.backend.zuluresh.com/admin/product/getAllproduct`
      );

      // Check if the API call was successful
      if (response.data.status) {
        const products = response.data.data;
        setSearchResults(
          products?.filter((product) =>
            product?.title?.toLowerCase().includes(query?.toLowerCase())
          )
        );
      } else {
        console.error("Failed to fetch products");
        setSearchResults([]); // Clear search results on failure
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
      setSearchResults([]); // Clear search results on error
    }
  };

  const placeholderTexts = [
    "Search here",
    "What are you looking for?",
    "Find your products",
    "Start your search",
  ];

  useEffect(() => {
    let currentIndex = 0;
    const intervalId = setInterval(() => {
      currentIndex = (currentIndex + 1) % placeholderTexts.length;
      setPlaceholder(placeholderTexts[currentIndex]);
    }, 2000);
    return () => clearInterval(intervalId);
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleSelectLocation = (location) => {
    setAddress(location);
    setLocationModalOpen(false);
  };

  const formatTitleForUrl = (title) => {
    return title.replace(/\s+/g, "-").replace(/:/g, "");
  };

  const handleLogout = () => {
    Cookies.remove("token");
    sessionStorage.clear();
    window.location.href = "/";
  };

  const handleNaviagteCheckout = (e) => {
    e.preventDefault();
    setUpdateCart(udpateCart + 12)
  };

  const handleClickOutside = (event) => {
    if (searchBoxRef.current && !searchBoxRef.current.contains(event.target)) {
      setSearchResults([]);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <AppBar
      position="static"
      color="default"
      sx={{ padding: "0 20px", backgroundColor: "white", color: "#333" }}
      className={style.navbar}
    >
      <Toolbar disableGutters className={style.container_nav}>
        <a href="/">
          <Box
            component="img"
            src={logo}
            alt="Logo"
            title="Zuluresh logo"
            loading="lazy"
            width="40px"
            height="40px"
            sx={{ marginRight: 2 }}
            className={style.logo}
          />
        </a>

        <Box
          sx={{ display: "flex", alignItems: "center", marginRight: 4 }}
          onClick={() => setLocationModalOpen(true)}
          style={{ cursor: "pointer" }}
          className={style.locationBox}
        >
          <LocationOnIcon />
          <Typography className={style.location}>
            <h6 style={{ margin: "0px" }}>
              {" "}
              Location <KeyboardArrowDownIcon />
            </h6>
            <p style={{ fontSize: "14px" }}>
              {address?.pincode}, {address?.state}
            </p>
          </Typography>
        </Box>

        <Box
          sx={{ flexGrow: 1, position: "relative" }}
          className={style.search_box}
        >
          <TextField
            placeholder={placeholder}
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={(e) => {
              const value = e.target.value;
              setSearchTerm(value);
              setIsInputNotEmpty(value.trim() !== "");
              if (value) {
                fetchSearchResults(value);
              } else {
                setSearchResults([]);
              }
            }}
            sx={{ width: "100%", maxWidth: 400 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            className={style.searchBar}
          />
          {isInputNotEmpty && (
            <Box
              sx={{
                position: "absolute",
                top: "100%",
                left: 0,
                right: 0,
                bgcolor: "white",
                boxShadow: 1,
                zIndex: 10,
                width: "400px",
              }}
              className={style.searchBar_box}
            >
              <List>
                {searchResults.map((result, index) => (
                  <ListItem key={index} button className={style.list}>
                    <img
                      onClick={() =>
                        (window.location.href = `/product/${result?.title?.replace(
                          /\s+/g,
                          "-"
                        )}`)
                      }
                      src={result?.productImg[0]?.url}
                      height={50}
                      width={60}
                    />
                    <div>
                      <ListItemText
                        primary={result?.title}
                        onClick={() =>
                          (window.location.href = `/product/${result?.title?.replace(
                            /\s+/g,
                            "-"
                          )}`)
                        }
                      />
                      <div className={style.product_price}>
                        <ListItemText secondary={`₹${result?.price}`} />
                        <button onClick={() => handleAddToCart(result)}>
                          Add
                        </button>
                      </div>
                    </div>
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </Box>

        <Box className={style.buttonsContainer}>
          <Button
            color="inherit"
            sx={{ textTransform: "none", marginRight: 2 }}
            onClick={handleCategoriesClick}
            className={style.category_btn}
          >
            <TuneIcon sx={{ marginRight: 1 }} />
            <p>Categories</p>
          </Button>
          <Popover
            open={Boolean(categoriesAnchorEl)}
            anchorEl={categoriesAnchorEl}
            onClose={handleCategoriesClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <List>
              {allcategory.map((item) => (
                <ListItem
                  button
                  onClick={() =>
                    (window.location.href = `/category/${formatTitleForUrl(
                      item?.categoryName
                    )}`)
                  }
                >
                  <ListItemText primary={item?.categoryName} />
                </ListItem>
              ))}
            </List>
          </Popover>

          <Button
            color="inherit"
            className={style.cart_btn}
            onClick={(e) => handleNaviagteCheckout(e)}
          >
            <ShoppingCartIcon className={style.icon} />
            <p>
              {/* ₹{billDetails?.TotalPrice ? billDetails?.TotalPrice : totalPrice}{" "} */}
              <p>
                {billDetails?.totalItems
                  ? billDetails?.totalItems
                  : cartItems.length}{" "}
                Items
              </p>
            </p>
          </Button>
          {token ? (
            <>
              <Button
                color="inherit"
                sx={{ textTransform: "none", marginLeft: 2 }}
                onClick={handleAccountClick}
                className={style.category_btn}
              >
                <AccountCircleIcon sx={{ marginRight: 1 }} />
                <p>account</p>
              </Button>
              <Popover
                open={Boolean(accountAnchorEl)}
                anchorEl={accountAnchorEl}
                onClose={handleAccountClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <List>
                  <ListItem
                    button
                    onClick={() => (window.location.href = "/update-profile")}
                  >
                    <ListItemText primary={"Profile"} />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => (window.location.href = "/all-orders")}
                  >
                    <ListItemText primary={"Orders"} />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => (window.location.href = "/privacy-policy")}
                  >
                    <ListItemText primary={"Privacy Policy"} />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => (window.location.href = "/add-address")}
                  >
                    <ListItemText primary={"Address"} />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => (window.location.href = "/faqs")}
                  >
                    <ListItemText primary={"FAQs"} />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() =>
                      (window.location.href = "/terms-and-conditions")
                    }
                  >
                    <ListItemText primary={"Terms And Condition"} />
                  </ListItem>
                  <ListItem button onClick={handleLogout}>
                    <ListItemText primary={"Logout"} />
                  </ListItem>
                </List>
              </Popover>
            </>
          ) : (
            <Button
              variant="contained"
              color="secondary"
              sx={{ marginLeft: 2 }}
              className={style.loginButton}
              onClick={() => (window.location.href = "/login")}
            >
              Login
            </Button>
          )}
        </Box>
        <IconButton
          edge="end"
          color="inherit"
          aria-label="menu"
          className={style.menuIcon2}
          onClick={() => (window.location.href = "/checkout")}
        >
          <ShoppingCartIcon />
          <span className={style.item_quantity}>
            {" "}
            {billDetails?.totalItems
              ? billDetails?.totalItems
              : cartItems.length}
          </span>
        </IconButton>
        <IconButton
          edge="end"
          color="inherit"
          aria-label="menu"
          className={style.menuIcon}
          onClick={toggleDrawer(true)}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
      <Box
        sx={{ flexGrow: 1, position: "relative" }}
        className={style.search_box_mob}
      >
        <TextField
          placeholder={placeholder}
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={(e) => {
            const value = e.target.value;
            setSearchTerm(value);
            setIsInputNotEmpty(value.trim() !== '');
            if (value) {
              fetchSearchResults(value);
            } else {
              setSearchResults([]);
            }
          }}
          sx={{ width: "100%" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        {isInputNotEmpty && (
          <Box
            sx={{
              position: "absolute",
              top: "100%",
              left: 0,
              right: 0,
              bgcolor: "white",
              boxShadow: 1,
              zIndex: 10,
            }}
            className={style.searchBar_box}
          >
            <List>
              {searchResults.map((result, index) => (
                <ListItem
                  key={index}
                  button
                  onClick={() =>
                    (window.location.href = `/product/${result?.title?.replace(
                      /\s+/g,
                      "-"
                    )}`)
                  }
                  className={style.list}
                >
                  <img
                    src={result?.productImg[0]?.url}
                    height={50}
                    width={60}
                  />
                  <div>
                    <ListItemText primary={result?.title} />
                    <div className={style.product_price}>
                      <ListItemText secondary={`₹${result?.price}`} />
                      <button onClick={() => handleAddToCart(result)}>
                        Add
                      </button>
                    </div>
                  </div>
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </Box>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <DrawerList toggleDrawer={toggleDrawer} />
      </Drawer>
      <LocationModal
        open={locationModalOpen}
        onSelectLocation={handleSelectLocation}
      />
    </AppBar>
  );
};

export default NavBar;
