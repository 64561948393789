import React from 'react'
import styles from "./SectionSixth.module.css"
import AccordionComponent from '../../AccordionComponent/AccordionComponent'

function SectionSixth() {
  return (
    <div className={styles.main}>
        <h2>FAQs</h2>
       <AccordionComponent  />
    </div>
  )
}

export default SectionSixth
