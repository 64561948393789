import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Breadcrumbs.module.css';

const Breadcrumbs = ({ paths }) => {
  return (
    <nav aria-label="breadcrumb">
      <ol className={styles.breadcrumb}>
        {paths?.map((path, index) => (
          <li key={index} className={styles.breadcrumbItem}>
            {index === paths.length - 1 ? (
              <span className={styles.breadcrumbCurrent}>{path?.label}</span>
            ) : (
              <Link className={styles.breadcrumbLink} to={path?.url}>{path?.label}</Link>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
