import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
} from "@mui/material";
import styles from "./UpdateProfile.module.css";
import NavBar from "../../NavBar/NavBar";
import { Helmet } from "react-helmet";
import Footer from "../../Footer/Footer";
import Cookies from "js-cookie";

const UpdateProfile = () => {
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [pincode, setPincode] = useState("");
  const [locality, setLocality] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const token = Cookies.get("token");
    const headers = {
      "x-auth-token": token,
      "Content-Type": "application/json",
    };

    axios
      .get("https://www.backend.zuluresh.com/user/details/getprofile", {
        headers,
      })
      .then((response) => {
        const data = response.data.data;
        setName(data.name || "");
        setGender(data.gender || "");
        setEmail(data.email || "");
        setPincode(data.address.pincode || "");
        setLocality(data.address.locality || "");
      })
      .catch((error) => console.error("Error fetching profile:", error));
  }, []);

  const validate = () => {
    const newErrors = {};
    if (!name) newErrors.name = "Name is required";
    if (!gender) newErrors.gender = "Gender is required";
    if (!email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Please enter a valid email address";
    }
    if (!pincode) {
      newErrors.pincode = "Pincode is required";
    } else if (!/^\d{6}$/.test(pincode)) {
      newErrors.pincode = "Please enter a valid 6-digit pincode";
    }
    if (!locality) newErrors.locality = "Locality is required";
    return newErrors;
  };

  const handleUpdate = () => {
    const token = Cookies.get("token");
    const headers = {
      "x-auth-token": token,
      "Content-Type": "application/json",
    };
    const newErrors = validate();
    if (Object.keys(newErrors).length === 0) {
      const updatedProfile = {
        name: name,
        gender: gender,
        email: email,
        address: {
          pincode: pincode,
          locality: locality,
        },
      };

      axios
        .put(
          "https://www.backend.zuluresh.com/user/details/updateProfile",
          updatedProfile,
          { headers }
        )
        .then((response) => {
          if (response.status === 200) {
            alert("Profile updated successfully!");
            window.location.reload()
          } else {
             alert("Failed to update profile");
          }
        })
        .catch((error) => {
          if (axios.isAxiosError(error)) {
            const { response } = error;
            const errorMessage =
              response?.data?.message || "Error updating profile";
            alert(errorMessage);
          } else {
            console.error("Network Error:", error.message);
            alert("Something went wrong");
          }
        });
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <>
      <Helmet>
        <title>Update Profile</title>
      </Helmet>
      <NavBar />
      <Container maxWidth="sm" className={styles.root}>
        <Typography variant="h5" gutterBottom>
          Update Profile
        </Typography>
        <form className={styles.form} noValidate autoComplete="off">
          <TextField
            className={styles.input}
            label="Name"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={!!errors.name}
            helperText={errors.name}
          />
          <TextField
            className={styles.input}
            label="Email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!errors.email}
            helperText={errors.email}
          />
          <TextField
            className={styles.input}
            label="Locality"
            variant="outlined"
            value={locality}
            onChange={(e) => setLocality(e.target.value)}
            error={!!errors.locality}
            helperText={errors.locality}
          />
               <TextField
            className={styles.input}
            label="Pincode"
            variant="outlined"
            value={pincode}
            onChange={(e) => setPincode(e.target.value)}
            error={!!errors.pincode}
            helperText={errors.pincode}
          />
          <Box className={styles.radioGroup}>
            <FormLabel component="legend">Gender</FormLabel>
            <RadioGroup
              row
              value={gender}
              onChange={(e) => setGender(e.target.value)}
            >
              <FormControlLabel value="male" control={<Radio />} label="Male" />
              <FormControlLabel
                value="female"
                control={<Radio />}
                label="Female"
              />
              <FormControlLabel
                value="other"
                control={<Radio />}
                label="Other"
              />
            </RadioGroup>
            {errors.gender && (
              <p className={styles.errorText}>{errors.gender}</p>
            )}
          </Box>
     
        </form>
        <br/>
        <Button
          className={styles.updateButton}
          variant="contained"
          onClick={handleUpdate}
        >
          Update
        </Button>
      </Container>
      <Footer />
    </>
  );
};

export default UpdateProfile;
