import React from "react";
import { hydrate, render } from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";

// Import your components
import CategoryPage from "./Component/Pages/CategoryPage/CategoryPage";
import ProductPage from "./Component/Pages/ProductPage/ProductPage";
import CheckoutPage from "./Component/Pages/CheckoutPage/CheckoutPage";
import PaymentPage from "./Component/Pages/PaymentPage/PaymentPage";
import Login from "./Component/Pages/Login/Login";
import AllProducts from "./Component/Pages/AllProducts/AllProducts";
import UpdateProfile from "./Component/Pages/UpdateProfile/UpdateProfile";
import UpdateAddress from "./Component/Pages/UpdateAddress/UpdateAddress";
import AddressCard from "./Component/AddressCard/AddressCard";
import AddAddress from "./Component/Pages/AddAddress/AddAddress";
import OrderSuccess from "./Component/Pages/OrderSuccess/OrderSuccess";
import OrderDetails from "./Component/Pages/OrderDetails/OrderDetails";
import AllOrders from "./Component/Pages/AllOrders/AllOrders";
import PrivacyPolicy from "./Component/Pages/PrivacyPolicy/PrivacyPolicy";
import FAQs from "./Component/Pages/FAQs/FAQs";
import TermsAndCondition from "./Component/Pages/TermsAndCondition/TermsAndCondition";
import ErrorPage from "./Component/Pages/ErrorPage/ErrorPage";
// import ErrorPage from "./Component/Pages/ErrorPage/ErrorPage";

// Get the root element from the DOM
const rootElement = document.getElementById("root");

// Define the React application structure
const appContent = (
  <React.StrictMode>
    <RecoilRoot>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="category/:category" element={<CategoryPage />} />
          <Route path="/product/:title" element={<ProductPage />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/payment" element={<PaymentPage />} />
          <Route path="/all-products/:setas" element={<AllProducts />} />
          <Route path="/update-profile" element={<UpdateProfile />} />
          <Route path="/update-address/:addressId" element={<UpdateAddress />} />
          <Route path="/address" element={<AddressCard />} />
          <Route path="/add-address" element={<AddAddress />} />
          <Route path="/order-success" element={<OrderSuccess />} />
          <Route path="/order-summary/:orderId" element={<OrderDetails />} />
          <Route path="/all-orders" element={<AllOrders />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/faqs" element={<FAQs />} />
          <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
          <Route path="/*" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </RecoilRoot>
  </React.StrictMode>
);

// Use hydrate if the root element has pre-rendered content, otherwise render
if (rootElement.hasChildNodes()) {
  hydrate(appContent, rootElement);
} else {
  render(appContent, rootElement);
}

// Performance measuring (optional)
reportWebVitals();
