import React from "react";
import styles from "./OrderSuccess.module.css";
import { Divider } from "@mui/material";
import Footer from "../../Footer/Footer";
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'

const OrderSuccess = () => {
  const orderId = JSON.parse(sessionStorage.getItem("order_status"));
  const { width, height } = useWindowSize()

  const handleDone = (e) => {
    e.preventDefault();
    sessionStorage.removeItem("order_status");
    sessionStorage.removeItem("cart");
    window.location.href = "/";
  };

  return (
    <div className={styles.container}>
          <Confetti
      width={width}
      height={height}
    />
      <h1 className={styles.title}>
        Order Successful{" "}
        <span role="img" aria-label="trophy">
          🏆
        </span>
      </h1>
      <div className={styles.buttonWrapper}>
        <h2>Check Details</h2>
        <Divider />
        <button
          className={styles.button}
          onClick={() =>
            (window.location.href = `/order-summary/${orderId?._id}`)
          }
        >
          View Order Details
        </button>
      </div>
      <button className={styles.doneButton} onClick={(e) => handleDone(e)}>
        Done
      </button>
    </div>
  );
};

export default OrderSuccess;
