import React, { useEffect, useState } from "react";
import {
  Drawer,
  Button,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import styles from "./OrderSummary.module.css";
import { useRecoilState } from "recoil";
import { updateCart } from "../Recoil/Recoil";
import {
  AddtoCart,
  getCheckoutData,
  removeProductfromCart,
  removetoCart,
} from "../../Api/Api";

const OrderSummary = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [updateCartState, setUpdateCartState] = useRecoilState(updateCart);
  const [loading, setLoading] = useState(false);
  const [billDetails, setBillingDetails] = useState([]);
  const [updateQantity, setUpdateQantity] = useState(0);
  const [productsData, setProductsData] = useState([]);

  // Function to get token from cookies
  function getToken() {
    return document.cookie.replace(
      /(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/,
      "$1"
    );
  }
  const token = getToken();
  // Effect to load cart data and handle updates
  useEffect(() => {
    const token = getToken();
    if (token) {
      setCartItems(productsData);
    } else {
      const cart = JSON.parse(sessionStorage.getItem("cart")) || [];
      setCartItems(cart);
    }
    if (updateCartState) {
      setDrawerOpen(true);
    }

    if (token) {
      handleCheckout();
    }
  }, [updateCartState, updateQantity]);

  // Function to handle checkout data fetching
  const handleCheckout = async () => {
    setLoading(true);
    try {
      const response = await getCheckoutData();
      setBillingDetails(response.data);
      setProductsData(response?.data?.productsData);
      setCartItems(response?.data?.productsData);
    } catch (error) {
      console.error("Error fetching checkout data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Function to handle removing product from cart in backend
  const handleRemoveProductFromCartBackend = async (id) => {
    try {
      await removeProductfromCart(id);
      setUpdateQantity(updateQantity + 2);
    } catch (error) {
      console.error("Error removing product from cart:", error);
    } finally {
      handleCheckout();
    }
  };

  // Function to handle adding product to cart in backend
  const handleAddToCartInBackend = async (id) => {
    try {
      await AddtoCart(id);
      setUpdateQantity(updateQantity + 2);
    } catch (error) {
      console.error("Error adding to cart:", error);
    } finally {
      handleCheckout();
    }
  };

  // Function to handle removing product quantity from cart in backend
  const handleRemoveFromCartInBackend = async (id) => {
    try {
      await removetoCart(id);
      setUpdateQantity(updateQantity + 2);
    } catch (error) {
      console.error("Error removing from cart:", error);
    } finally {
      handleCheckout();
    }
  };

  // Function to handle quantity change of an item
  const handleQuantityChange = (id, delta) => {
    const token = getToken();
    if (token && delta > 0) {
      handleAddToCartInBackend(id);
    } else if (token && delta < 0) {
      handleRemoveFromCartInBackend(id);
    }

    const newCartItems = cartItems.map((item) =>
      item.id === id
        ? { ...item, quantity: Math.max(item.quantity + delta, 1) }
        : item
    );
    setCartItems(newCartItems);
    sessionStorage.setItem("cart", JSON.stringify(newCartItems));
    setUpdateCartState(updateCartState + 10);
  };

  // Function to handle item removal
  const handleRemoveItem = (id) => {
    const token = getToken();
    if (token) {
      handleRemoveProductFromCartBackend(id);
    }
    const newCartItems = cartItems.filter((item) => item.id !== id);
    setCartItems(newCartItems);
    sessionStorage.setItem("cart", JSON.stringify(newCartItems));
    setUpdateCartState(updateCartState + 5);
  };

  const handleNavigateCheckout = (e) => {
    e.preventDefault();
    sessionStorage.setItem("checkout_Status", true);
    window.location.href = "/checkout";
  };

  // Calculate total amount from cart items
  const totalAmount = cartItems.reduce(
    (acc, item) => acc + item.price * item.quantity,
    0
  );

  sessionStorage.setItem("totalprice", JSON.stringify(totalAmount));

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isDrawerOpen) {
        handleCheckout();
        setUpdateCartState(updateCartState + 10);
      }
    }, 2000);
    return () => clearTimeout(timer); // Cleanup the timer if the component unmounts
  }, [isDrawerOpen, handleCheckout, setUpdateCartState, updateCartState]);

  return (
    <div>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <Box sx={{ width: 400, padding: 2 }} className={styles.box}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <IconButton onClick={() => setDrawerOpen(false)}>
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">Order Summary</Typography>
            <div></div>
          </Box>

          <List>
            {cartItems.length == 0 && <p>No Products In the cart</p>}
            {cartItems.map((item) => (
              <ListItem
                key={item?.Product_id ? item?.Product_id : item?.id}
                divider
              >
                <Box width="100%">
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <ListItemText
                      primary={
                        item?.Product_title ? item?.Product_title : item?.name
                      }
                      secondary={`${
                        item?.Product_unit ? item?.Product_unit : item?.unit
                      }${
                        item?.Product_measureUnit
                          ? item?.Product_measureUnit
                          : item?.measureunit
                      }`}
                    />
                    <IconButton
                      sx={{ fontSize: 16 }}
                      onClick={() =>
                        handleRemoveItem(
                          item?.Product_id ? item?.Product_id : item?.id
                        )
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                      display: "flex",
                      gap: "10px",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <p style={{ textDecoration: "line-through" }}>
                        ₹
                        {item?.Product_MRP
                          ? item?.Product_MRP
                          : item?.originalPrice?.toFixed(2)}
                      </p>
                      <p style={{ color: "#941a49" }}>
                        ₹
                        {(item?.Product_price
                          ? item?.Product_price
                          : item?.price 
                        )?.toFixed(2)}
                      </p>
                    </div>

                    <Box
                      display="flex"
                      alignItems="center"
                      className={styles.quntity_box}
                    >
                      <IconButton
                        sx={{ fontSize: 16 }}
                        onClick={() =>
                          handleQuantityChange(
                            item?.Product_id ? item?.Product_id : item?.id,
                            -1
                          )
                        }
                      >
                        <RemoveIcon />
                      </IconButton>
                      <Typography>
                        {item?.Product_quantity
                          ? item?.Product_quantity
                          : item?.quantity}
                      </Typography>
                      <IconButton
                        sx={{ fontSize: 16 }}
                        onClick={() =>
                          handleQuantityChange(
                            item?.Product_id ? item?.Product_id : item?.id,
                            1
                          )
                        }
                      >
                        <AddIcon />
                      </IconButton>
                    </Box>
                  </Typography>
                </Box>
              </ListItem>
            ))}
          </List>
          <Box
            mt={2}
            p={2}
            border="1px dashed #ccc"
            className={styles.billing_container}
          >
            <Typography variant="h6" className={styles.billing_details}>
              <p>Bill Details:</p> <p></p>
            </Typography>
            <Typography variant="body2" className={styles.billing_details}>
              <p>Item Total:</p>{" "}
              <p>
                ₹
                {billDetails?.TotalMrp
                  ? billDetails?.TotalMrp.toFixed(2)
                  : totalAmount.toFixed(2)}
              </p>
            </Typography>
            {billDetails?.Discount && (
              <Typography variant="body2" className={styles.billing_details}>
                <p>Discount</p>{" "}
                <p>
                  ₹
                  {billDetails?.Discount ? billDetails?.Discount.toFixed(2) : 0}
                </p>
              </Typography>
            )}

            <Typography variant="body2" className={styles.billing_details}>
              <p>SubTotal:</p>{" "}
              <p>
                ₹
                {billDetails?.Price
                  ? billDetails?.Price.toFixed(2)
                  : totalAmount.toFixed(2)}
              </p>
            </Typography>
               {token  &&
               (
                <Typography variant="body2" className={styles.billing_details}>
                <p>Shipping</p>{" "}
                <p>
                  ₹
                  {billDetails?.Shipping == 0   ? "free" : billDetails?.Shipping?.toFixed(2)}
                </p>
              </Typography>
               )
               }
     
       
            <Typography
              variant="h6"
              color="#941a49"
              className={styles.billing_details}
            >
              <p>Total:</p>{" "}
              <p>
                {" "}
                ₹
                {(billDetails?.TotalPrice
                  ? billDetails?.TotalPrice
                  : totalAmount
                ).toFixed(2)}
              </p>
            </Typography>
          </Box>
          <Button
            variant="contained"
            className={styles.checkout_btn}
            fullWidth
            sx={{ mt: 2 }}
            onClick={(e) => handleNavigateCheckout(e)}
          >
            Checkout
          </Button>
        </Box>
      </Drawer>
    </div>
  );
};

export default OrderSummary;
