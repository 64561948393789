import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from './BestSellers.module.css';
import ProductCard from '../../ProductCard/ProductCard';


const BestSellers = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          `https://www.backend.zuluresh.com/admin/product/getAllproduct?setAs=Best Seller`
        );
        if (response.data.status) {
          setProducts(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  return (
    <div className={styles.main}>
      <div className={styles.heading}>
        <div>
          <h2>Best Sellers</h2>
          <p>Most popular products near you</p>
        </div>
        <button onClick={() => (window.location.href = "/all-products/Best-Seller")}>
          View All →
        </button>
      </div>
      <br/>
        <div  className={styles.product_container}> 
        {products.map((product, index) => (
          <div
            className={styles.slider}
            key={index}
            onClick={() => {
              if (product?.name) {
                const formattedName = product.name.replace(/\s+/g, "-").toLowerCase();
                window.location.href = `/product/${formattedName}`;
              }
            }}
          >
            <ProductCard
              stock={product?.Stock}
              measureunit={product?.measureUnit}
              unit={product?.unit}
              image={product?.productImg[0]?.url}
              name={product?.title}
              originalPrice={product?.MRP}
              discount={product?.discount}
              price={product?.price}
              specialOffer={product.specialOffer}
              id={product._id}
            />
          </div>
        ))}
        </div>

    </div>
  );
};

export default BestSellers;
