import React from 'react';
import styles from './PrivacyPolicy.module.css';
import NavBar from '../../NavBar/NavBar';
import { Helmet } from "react-helmet";
import Footer from '../../Footer/Footer';

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <title>zuluresh | Buy Fresh & High Quality Meat at Best Price on Zuluresh</title>
        <meta
          name="description"
          content="Order meat online in just a few clicks. Zuluresh offers 100% fresh & best quality Chicken, Fish/Prawns, and Mutton products online."
        />
        <link rel="canonical" href="https://www.zuluresh.com/privacy-policy" />
        <meta name="title" content="zuluresh" />
        <meta name="keyword" content="zuluresh, Buy Fresh & High Quality Meat, Chicken, Fish, Prawns, Mutton" />
        {/* Schema Markup */}
        <script type="application/ld+json">
          {`
          {
            "@context": "http://schema.org",
            "@type": "WebSite",
            "name": "zuluresh",
            "url": "https://www.zuluresh.com/privacy-policy",
            "description": "Order meat online in just a few clicks. Zuluresh offers 100% fresh & best quality Chicken, Fish/Prawns, and Mutton products online.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": "https://www.zuluresh.com/privacy-policy",
              "query-input": "required name=privacy-policy"
            }
          }
          `}
        </script>
        {/* End Schema Markup */}
        {/* Google tag (gtag.js) */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-SJPR5MYB8S"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-SJPR5MYB8S');
          `}
        </script>
    {/* Meta Pixel Code */}
    <script>
      {`
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '2583408375194861');
      fbq('track', 'PageView');
      `}
    </script>
    <noscript>
      {`
      <img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=2583408375194861&ev=PageView&noscript=1"/>
      `}
    </noscript>
    {/* End Meta Pixel Code */}
    {/* Hotjar Tracking Code for https://www.zuluresh.com/ */}
    <script>
      {`
      (function (h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: 5054101, hjsv: 6 };
        a = o.getElementsByTagName("head")[0];
        r = o.createElement("script");
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
      `}
    </script>
      </Helmet>
      <NavBar />
      <div className={styles.container}>
        <h1 className={styles.header}>Privacy Policy</h1>
        <p className={styles.intro}>
          Your privacy is important to us. This privacy policy explains the types of information we collect from you, how we use that information, and the measures we take to protect it.
        </p>

        <section className={styles.section}>
          <h2 className={styles.subHeader}>Information We Collect</h2>
          <p>
            We may collect various types of information in connection with our services, including:
          </p>
          <ul className={styles.list}>
            <li>Personal information you provide to us directly, such as your name, email address, and phone number.</li>
            <li>Information about your usage of our services, such as your IP address, browser type, and pages visited.</li>
            <li>Data from cookies and similar technologies that help us understand your preferences and improve our services.</li>
          </ul>
        </section>

        <section className={styles.section}>
          <h2 className={styles.subHeader}>How We Use Your Information</h2>
          <p>
            The information we collect is used for various purposes, including:
          </p>
          <ul className={styles.list}>
            <li>To provide and improve our services.</li>
            <li>To communicate with you about updates, promotions, and offers.</li>
            <li>To analyze usage patterns and enhance user experience.</li>
            <li>To comply with legal requirements and prevent fraudulent activities.</li>
          </ul>
        </section>

        <section className={styles.section}>
          <h2 className={styles.subHeader}>Sharing Your Information</h2>
          <p>
            We may share your information with third parties in the following circumstances:
          </p>
          <ul className={styles.list}>
            <li>With service providers who assist us in operating our services.</li>
            <li>With affiliates and partners for marketing and promotional purposes.</li>
            <li>As required by law or in response to legal processes.</li>
            <li>To protect the rights, property, or safety of our users and the public.</li>
          </ul>
        </section>

        <section className={styles.section}>
          <h2 className={styles.subHeader}>Your Choices and Rights</h2>
          <p>
            You have certain rights and choices regarding the information we collect:
          </p>
          <ul className={styles.list}>
            <li>You can opt-out of receiving marketing communications from us.</li>
            <li>You can access, update, or delete your personal information.</li>
            <li>You can manage your cookie preferences through your browser settings.</li>
          </ul>
        </section>

        <section className={styles.section}>
          <h2 className={styles.subHeader}>Security Measures</h2>
          <p>
            We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no security system is impenetrable, and we cannot guarantee the security of your data.
          </p>
        </section>

        <section className={styles.section}>
          <h2 className={styles.subHeader}>Changes to This Policy</h2>
          <p>
            We may update this privacy policy from time to time. Any changes will be posted on this page, and we encourage you to review our policy periodically.
          </p>
        </section>

        <section className={styles.section}>
          <h2 className={styles.subHeader}>Contact Us</h2>
          <p>
            If you have any questions or concerns about our privacy practices, please contact us at <a href="mailto:support@zuluresh.com" className={styles.link}>support@zuluresh.com</a>.
          </p>
        </section>
      </div>
      <Footer/>
    </>
  );
};

export default PrivacyPolicy;
