import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from './AllOrders.module.css';
import NavBar from '../../NavBar/NavBar';
import Footer from '../../Footer/Footer';


const AllOrders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrderData = async () => {
      const getToken = () => document.cookie.replace(/(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/, "$1");
      const token = getToken();

      try {
        const headers = {
          "x-auth-token": token,
          "Content-Type": "application/json",
        };
        const response = await axios.get('https://www.backend.zuluresh.com/user/order/allOrders', { headers });
        setOrders(response?.data?.data || []); // Assuming 'data' contains the orders list
      } catch (error) {
        console.error('Error fetching the order data', error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrderData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (orders.length === 0) {
    return <div>No orders found.</div>;
  }

  return (
    <div className={styles.main}>
      <NavBar />
      <br/>
      <h1 className={styles.title}>Orders</h1>
      <div className={styles.container}>
        {orders.map((order, index) => (
          <div key={index} className={styles.order}         onClick={() =>
            (window.location.href = `/order-summary/${order?._id}`)
          }>
            <div className={styles.orderHeader}>
              <div className={styles.orderImage}>
                <img src={order?.productImg?.url} alt="Product" className={styles.productImg} />
              </div>
              <div className={styles.orderDetails}>
                <span className={styles.orderId}>{order?.orderId}</span>
                <span className={styles.orderItems}>{order?.totalItems} items</span>
                <span className={styles.orderPrice}>₹ {order?.totalPrice}</span>
              </div>
              <div className={`${styles.orderStatus} ${order?.orderStatus === 'Delivered' ? styles.delivered : styles.pending}`}>
                {order?.orderStatus}
              </div>
            </div>
            <div className={styles.orderFooter}>
              <div className={styles.orderDate}>
                <p>Order Date</p>
                <p>{new Date(order?.orderDate).toLocaleString()}</p>
              </div>
              <div className={styles.deliveryDate}>
                <p>Delivery Date</p>
                <p>{order?.shippingInfo?.deliverySlot?.day}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default AllOrders;
