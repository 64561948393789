import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import style from "./FirstSection.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { getAllBanner } from "../../../Api/Api";
import { GoArrowLeft, GoArrowRight } from "react-icons/go";

function FirstSection() {
  const [index, setIndex] = useState(0);
  const [allBanner, setAllBanner] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const handleNavigate = (type) => {
    window.location.href =`/category/${type}`;
  };

  useEffect(() => {
    handleAllBanner();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 500);
  };

  const handleAllBanner = async () => {
    try {
      const response = await getAllBanner();
      setAllBanner(response?.data || []);
    } catch (error) {
      console.log(error);
    }
  };

  const goToPrevSlide = () => {
    const prevIndex = index === 0 ? filteredBanners.length - 1 : index - 1;
    setIndex(prevIndex);
  };

  const goToNextSlide = () => {
    const nextIndex = index === filteredBanners.length - 1 ? 0 : index + 1;
    setIndex(nextIndex);
  };

  const filteredBanners = allBanner.filter(
    (banner) => banner.setFor === (isMobile ? "Phone" : "Desktop")
  );

  return (
    <div className={style.main}>
      <Carousel
        slide={true}
        onSelect={handleSelect}
        indicators={false}
        controls={false}
        activeIndex={index}
      >
        {filteredBanners.map((item, idx) => (
          <Carousel.Item key={idx}>
            <div
              className={style.img_box}
              onClick={() => handleNavigate(item?.category)}
            >
              <img
                className={style.img}
                src={item?.bannerImg?.url}
                alt="banner"
                title="banner"
                loading="lazy"
                width="auto"
                height="auto"
              />
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
      {filteredBanners.length > 1 && (
        <div className={style.customControls}>
          <button onClick={goToPrevSlide} className={style.prevButton}>
            <GoArrowLeft />
          </button>
          <button onClick={goToNextSlide} className={style.nextButton}>
            <GoArrowRight />
          </button>
        </div>
      )}
    </div>
  );
}

export default FirstSection;
