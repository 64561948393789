// ProductPage.js

import React, { useEffect, useState } from "react";
import styles from "./ProductPage.module.css";
import NavBar from "../../NavBar/NavBar";
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs";
import FourthSection from "../../Section/FourthSection/FourthSection";
import ZoomImage from "../../ZoomImage/ZoomImage";
import OrderSummary from "../../OrderSummary/OrderSummary";
import {
  Box,
  Button,
  Typography,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useRecoilState } from "recoil";
import { updateCart } from "../../Recoil/Recoil";
import { AddtoCart, removetoCart } from "../../../Api/Api";
import Cookies from "js-cookie";
import Footer from "../../Footer/Footer";




function ProductPage() {
  const { title } = useParams();
  const formatTitleForUrl = (title) => {
    return title ? title.replace(/\s+/g, "-").replace(/:/g, "") : "";
  };

  const [updateCartState, setUpdateCartState] = useRecoilState(updateCart);
  const formatPrice = (price) => parseFloat(price).toFixed(2);
  const [product, setProduct] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          `https://www.backend.zuluresh.com/admin/product/getAllproduct`
        );
        if (response.data.status) {
          const filterData = response.data.data.find(
            (item) => formatTitleForUrl(item?.title) === title
          );
          setProduct(filterData);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, [title]);

  const paths = [
    { label: "Home", url: "/" },
    { label: title, url: `/product/${title}` },
  ];

  const [quantity, setQuantity] = useState(1);

  const handleIncrease = (id) => {
    function getToken() {
      return document.cookie.replace(
        /(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/,
        "$1"
      );
    }
  
    // Retrieve token
    const token = getToken();
    if(token){
      handleAddToCartInBeckend(id);
    }
    setQuantity(quantity + 1);
  };

  const handleDecrease = (id) => {
    function getToken() {
      return document.cookie.replace(
        /(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/,
        "$1"
      );
    }
  
    // Retrieve token
    const token = getToken();
    if(token){
      handleRemoveToCartInBeckend(id);
    }
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleAddToCartInBeckend = async (id) => {
    try {
      const response = await AddtoCart(id);
    } catch (error) {
      console.log(error);
    }
  };

  
  const handleRemoveToCartInBeckend = async (id) => {
    try {
      const response = await removetoCart(id);
    } catch (error) {
      console.log(error);
    }
  };



  // Handle add to cart
  const handleAddToCart = (id) => {
    function getToken() {
      return document.cookie.replace(
        /(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/,
        "$1"
      );
    }
  
    // Retrieve token
    const token = getToken();
    if(token){
      handleAddToCartInBeckend(id);
    }
    // Get the current cart from session storage or initialize an empty array
    const cart = JSON.parse(sessionStorage.getItem("cart")) || [];

    // Check if the product is already in the cart
    const productIndex = cart.findIndex((item) => item.id === product._id);

    if (productIndex !== -1) {
      // Product is already in the cart, increase the quantity
      cart[productIndex].quantity += quantity;
    } else {
      // Product is not in the cart, add it with specified quantity
      cart.push({
        id: product._id,
        name: product.title,
        image: product.productImg?.[0]?.url,
        originalPrice: product.MRP,
        discount: product.discount,
        price: product.price,
        measureunit: product.measureunit,
        unit: product.unit,
        quantity: quantity,
      });
    }

    // Save the updated cart back to session storage
    sessionStorage.setItem("cart", JSON.stringify(cart));
    setUpdateCartState(updateCartState + 4); // Trigger re-render for OrderSummary
  };

  function convertToJSX(htmlString) {
    return React.createElement("div", {
      dangerouslySetInnerHTML: { __html: htmlString },
    });
  }

  if (!product) {
    return <div>Loading...</div>;
  }

  return (
    <>
        <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <title>zuluresh | Buy Fresh & High Quality Meat at Best Price on Zuluresh</title>
        <meta
          name="description"
          content="Order meat online in just a few clicks.Zuluresh offers 100% fresh & best quality Chicken, Fish/Prawns and Mutton products online."
        />
        <link rel="canonical" href={`https://www.zuluresh.com/product/${title}`} />
        <meta name="title" content="zuluresh" />
        <meta name="keyword" content="zuluresh | Buy Fresh & High Quality Meat at Best Price on Zuluresh" />
        {/* Schema Markup */}
        <script type="application/ld+json">
          {`
          {
            "@context": "http://schema.org",
            "@type": "WebSite",
            "name": "zuluresh",
            "url": "https://www.zuluresh.com/product/${title}",
            "description": "Order meat online in just a few clicks.Zuluresh offers 100% fresh & best quality Chicken, Fish/Prawns and Mutton products online.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": "https://www.zuluresh.com/product/${title}",
              "query-input": "required name=${title}"
            }
          }
          `}
        </script>
        {/* End Schema Markup */}
        {/* Google tag (gtag.js) */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-SJPR5MYB8S"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-SJPR5MYB8S');
          `}
        </script>
    {/* Meta Pixel Code */}
    <script>
      {`
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '2583408375194861');
      fbq('track', 'PageView');
      `}
    </script>
    <noscript>
      {`
      <img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=2583408375194861&ev=PageView&noscript=1"/>
      `}
    </noscript>
    {/* End Meta Pixel Code */}
    {/* Hotjar Tracking Code for https://www.zuluresh.com/ */}
    <script>
      {`
      (function (h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: 5054101, hjsv: 6 };
        a = o.getElementsByTagName("head")[0];
        r = o.createElement("script");
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
      `}
    </script>
      </Helmet>
      <NavBar />
      <div className={styles.main}>
        <div className={styles.header}>
          <Breadcrumbs paths={paths} />
          {/* <h1>{product.title}</h1> */}
        </div>
        <div className={styles.card}>
          <OrderSummary />
          <div className={styles.img_box_products}>
          <ZoomImage
            productImage={product?.productImg?.[0]?.url}
            producttitle={product?.title}
          />
          </div>
     
          <div className={styles.details}>
            <p className={styles.name} >{product?.title}</p>
            <p className={styles.description_box} style={{color:"gray"}}>{convertToJSX(product?.description)}</p>
            <div style={{ display: "flex", gap: "5px" }}>
              <p className={styles.originalPrice}>₹{product?.MRP}</p>
              <p className={styles.discount}>{product?.discount}</p>
            </div>
            <div className={styles.total_price}>
              <p className={styles.price}>₹{ formatPrice(product?.price)}</p>
              <Box
                display="flex"
                alignItems="center"
                className={styles.quntity_box}
              >
                <IconButton sx={{ fontSize: 16 }} onClick={()=>handleDecrease(product._id)}>
                  <RemoveIcon />
                </IconButton>
                <Typography>{quantity}</Typography>
                <IconButton sx={{ fontSize: 16 }} onClick={()=>handleIncrease(product._id)}>
                  <AddIcon />
                </IconButton>
              </Box>
            </div>
            <Button 
              variant="contained" 
              className={styles.continueButton}
              onClick={()=>handleAddToCart(product._id)}
            >
              Add To Cart
            </Button>
          </div>
        </div>
        <br/>
        <FourthSection />
      </div>
      <Footer />
    </>
  );
}

export default ProductPage;
