import React, { useEffect, useState } from "react";
import styles from "./CategoryPage.module.css";
import chickenImg from "../../Images/Chicken-Biryani-Cut-scaled 1.webp";
import NavBar from "../../NavBar/NavBar";
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs";
import { Helmet } from "react-helmet";
import {
  getAllCategory,
  getAllSubCategory,
  getAllSubCategoryProduct,
} from "../../../Api/Api";
import { useParams } from "react-router-dom";
import ProductCard from "../../ProductCard/ProductCard";
import OrderSummary from "../../OrderSummary/OrderSummary";
import Footer from "../../Footer/Footer";

const formatTitleForUrl = (title) => {
  return title.replace(/\s+/g, "-").replace(/:/g, "");
};

function CategoryPage() {
  const [allSubCategory, setAllSubCategory] = useState([]);
  const { category } = useParams();
  const [allProducts, setAllProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleAllCategory();
  }, []);

  const handleAllCategory = async () => {
    setLoading(true);
    try {
      const response = await getAllCategory();
      if (response.status) {
        const filterData = response.data.find(
          (item) => formatTitleForUrl(item?.categoryName) === category
        );
        handleAllSubCategory(filterData._id);
        handleAllProducts();
        setLoading(false);
      }
      console.log(response.data, "response aa raha");
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleAllSubCategory = async (id) => {
    setLoading(true);
    try {
      const response = await getAllSubCategory(id);
      if (response.status) {
        setAllSubCategory(response.data);
        setLoading(false);
      }
      console.log(response.data, "response aa raha");
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const convertHyphenToSpace = (str) => {
    return str
      .split('-') // Split the string by hyphens
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(' '); // Join the words with spaces
  };
  const decodeCategoryString = (str) => {
    return decodeURIComponent(str).replace(/&/g, '%26');
  };
  const handleAllProducts = async (subcategory = "") => {
    setLoading(true);
    try {
      const response = await getAllSubCategoryProduct(convertHyphenToSpace(category), decodeCategoryString(subcategory));
      if (response.status) {
        setAllProducts(response.data);
        setLoading(false);
      }
      console.log(response.data, "response aa raha");
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const paths = [
    { label: "Home", url: "/" },
    { label: category, url: `/${category}` },
  ];

  return (
    <>
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <title>zuluresh | Buy Fresh & High Quality Meat at Best Price on Zuluresh</title>
        <meta
          name="description"
          content="Order meat online in just a few clicks.Zuluresh offers 100% fresh & best quality Chicken, Fish/Prawns and Mutton products online."
        />
        <link rel="canonical" href={`https://www.zuluresh.com/category/${category}`} />
        <meta name="title" content="zuluresh" />
        <meta name="keyword" content="zuluresh | Buy Fresh & High Quality Meat at Best Price on Zuluresh" />
        {/* Schema Markup */}
        <script type="application/ld+json">
          {`
          {
            "@context": "http://schema.org",
            "@type": "WebSite",
            "name": "zuluresh",
            "url": "https://www.zuluresh.com/category/${category}",
            "description": "Order meat online in just a few clicks.Zuluresh offers 100% fresh & best quality Chicken, Fish/Prawns and Mutton products online.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": "https://www.zuluresh.com/category/${category}",
              "query-input": "required name=${category}"
            }
          }
          `}
        </script>
        {/* End Schema Markup */}
        {/* Google tag (gtag.js) */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-SJPR5MYB8S"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-SJPR5MYB8S');
          `}
        </script>
    {/* Meta Pixel Code */}
    <script>
      {`
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '2583408375194861');
      fbq('track', 'PageView');
      `}
    </script>
    <noscript>
      {`
      <img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=2583408375194861&ev=PageView&noscript=1"/>
      `}
    </noscript>
    {/* End Meta Pixel Code */}
    {/* Hotjar Tracking Code for https://www.zuluresh.com/ */}
    <script>
      {`
      (function (h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: 5054101, hjsv: 6 };
        a = o.getElementsByTagName("head")[0];
        r = o.createElement("script");
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
      `}
    </script>
      </Helmet>
      <NavBar />
      <OrderSummary />
      <div className={styles.main}>
        <Breadcrumbs paths={paths} />
        <h1 hidden>{category}</h1>
        {loading && <p>Loading...</p>}
        {allSubCategory.length === 0 && <p>No Sub Category</p>}
        <br/>
        <div className={styles.outer_container}>
          <div className={styles.seconds_box}>
            <div
              className={styles.product_box}
              onClick={() => handleAllProducts()}
            >
              <img
                src={chickenImg}
                alt="all"
                title="all"
                loading="lazy"
                width="55px"
                height="55px"
                style={{borderRadius:"50%"}}
              />
              <p className={styles.subCategoryName}>All</p>
            </div>
            {allSubCategory.map((item) => (
              <div
                key={item._id}
                className={styles.product_box}
                onClick={() => handleAllProducts(item?.subCategoryName)}
              >
                <img
                  src={item?.subCategoryImg.url}
                  alt={item?.subCategoryName}
                  title={item?.subCategoryName}
                  loading="lazy"
                  width="65px"
                  height="65px"
                />
                <p className={styles.subCategoryName}>{item?.subCategoryName}</p>
              </div>
            ))}
          </div>
          <div className={styles.container}>
            {allProducts.length === 0 && <p>No Product </p>}
            {allProducts.map((product, index) => (
              <div className={styles.slider} key={index}>
                <ProductCard
                  stock={product?.Stock}
                  measureunit={product?.measureUnit}
                  unit={product?.unit}
                  image={product?.productImg[0]?.url}
                  name={product?.title}
                  originalPrice={product?.MRP}
                  discount={product?.discount}
                  price={product?.price}
                  specialOffer={product.specialOffer}
                  id={product._id}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CategoryPage;
