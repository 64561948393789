import React from "react";
import { Button } from "@mui/material";
import styles from "./CustomButton.module.css";
import DeliveryIcon from "@mui/icons-material/LocalShipping"; // Example icon, change to the one you need

const CustomButton = ({ active, label }) => {
  return (
    <Button className={`${styles.button} ${active ? styles.active : styles.inactive}`}>
      <DeliveryIcon className={styles.icon} style={{ color: active ? "#A30553" : "rgba(0, 0, 0, 0.38)" }} />
      {label}
    </Button>
  );
};

export default CustomButton;
