import React, { useEffect, useState } from "react";
import styles from "./PaymentPage.module.css";
import NavBar from "../../NavBar/NavBar";
import {
  Box,
  Button,
  Typography,
  Divider,
  CircularProgress,
} from "@mui/material";

import CustomButton from "../../CustomButton/CustomButton";
import {
  Radio,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import { Helmet } from "react-helmet";
import axios from "axios";
import { getCheckoutData } from "../../../Api/Api";
import Footer from "../../Footer/Footer";
import Cookies from "js-cookie";

function PaymentPage() {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("cod");
  const [loading, setLoading] = useState(false);
  const [timeSlots, setTimeSlots] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState();
  const [billDetails, setBillDetails] = useState({});

  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      window.location.href = "/login";
    }
    const selectedSlot = JSON.parse(sessionStorage.getItem("selectedSlot"));
    setTimeSlots(selectedSlot);
    const defaultAddress = JSON.parse(sessionStorage.getItem("defaultAddress"));
    setSelectedAddress(defaultAddress);
    handleCheckout();
  }, []);

  const handleCheckout = async () => {
    setLoading(true);
    try {
      const response = await getCheckoutData();
      console.log(response?.data?.totalItems, "response chaekout");
      if(response?.data?.totalItems === 0){
        window.location.href="/checkout"
      }
      setBillDetails(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handlePlaceOrder = async (e) => {
    const promoCode = JSON.parse(sessionStorage.getItem("couponCode"));
    e.preventDefault();
    // Function to retrieve token from cookies
    function getToken() {
      return document.cookie.replace(
        /(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/,
        "$1"
      );
    }

    // Retrieve token
    const token = getToken();
    setLoading(true);
    try {
      const headers = {
        "x-auth-token": token, // Pass the token in the header
        "Content-Type": "application/json", // Set content type to JSON
      };
      const response = await axios.post(
        `https://www.backend.zuluresh.com/user/order/createOrder/${selectedAddress?._id}`,
        {
          deliverySlot: {
            day: timeSlots?.day,
            startTime: timeSlots?.startTime,
            endTime: timeSlots?.endTime,
          },
          promoCode: promoCode ? promoCode : "",
          paymentMethod: {
            cod: selectedPaymentMethod === "cod",
          },
        },
        { headers }
      );
      if (response.status) {
        sessionStorage.setItem(
          "order_status",
          JSON.stringify(response?.data?.data)
        );
        sessionStorage.removeItem("couponCode");
        window.location.href = "/order-success";
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handlePaymentChange = (event) => {
    setSelectedPaymentMethod(event.target.value);
  };

  return (
    <>
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <title>zuluresh | Buy Fresh & High Quality Meat at Best Price on Zuluresh</title>
        <meta
          name="description"
          content="Order meat online in just a few clicks.Zuluresh offers 100% fresh & best quality Chicken, Fish/Prawns and Mutton products online."
        />
        <link rel="canonical" href="https://www.zuluresh.com/payment" />
        <meta name="title" content="zuluresh" />
        <meta name="keyword" content="zuluresh | Buy Fresh & High Quality Meat at Best Price on Zuluresh" />
        {/* Schema Markup */}
        <script type="application/ld+json">
          {`
          {
            "@context": "http://schema.org",
            "@type": "WebSite",
            "name": "zuluresh",
            "url": "https://www.zuluresh.com/",
            "description": "Order meat online in just a few clicks.Zuluresh offers 100% fresh & best quality Chicken, Fish/Prawns and Mutton products online.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": "https://www.zuluresh.com/",
              "query-input": "required name=search_term_string"
            }
          }
          `}
        </script>
        {/* End Schema Markup */}
      </Helmet>
      <NavBar />
      <div className={styles.main}>
        <div
          style={{ display: "flex", padding: "20px" }}
          className={styles.custom_btn}
        >
          <CustomButton active={true} label="Delivery Summary" />
          <div className={styles.custom_line}></div>
          <CustomButton active={true} label="Payment Details" />
        </div>
        <br />
        <div className={styles.container}>
          <div className={styles.left_box}>
            <Box className={styles.paymentContainer}>
              <Typography
                variant="h6"
                component="div"
                className={styles.paymentHeader}
              >
                Pay with
              </Typography>
              <Box className={styles.paymentOptions}>
                <ul className={styles.optionList}>
                  {/* <li className={styles.optionItem}>UPI</li>
                  <li className={styles.optionItem}>Saved Cards</li>
                  <li className={styles.optionItem}>Debit/Credit Cards</li>
                  <li className={styles.optionItem}>Wallets</li>
                  <li className={styles.optionItem}>Netbanking</li> */}
                </ul>
                <Box className={styles.walletOptions}>
                  <FormControl component="fieldset">
                    {/* <FormLabel
                      component="legend"
                      className={styles.walletLabel}
                    >
                      Pay Via Wallet
                    </FormLabel> */}
                    <RadioGroup
                      aria-label="wallet"
                      name="wallet"
                      className={styles.walletGroup}
                      value={selectedPaymentMethod}
                      onChange={handlePaymentChange}
                    >
                      <FormControlLabel
                        value="cod"
                        control={<Radio />}
                        label="Cash on Delivery"
                        className={styles.walletOption}
                      />
                      {/* <FormControlLabel
                        value="paytm"
                        control={<Radio />}
                        label="PAYTM"
                        className={styles.walletOption}
                      />
                      <FormControlLabel
                        value="mobikwik"
                        control={<Radio />}
                        label="MOBIKWIK"
                        className={styles.walletOption}
                      />
                      <FormControlLabel
                        value="freecharge"
                        control={<Radio />}
                        label="FREECHARGE"
                        className={styles.walletOption}
                      /> */}
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Box>
            </Box>
          </div>
          <Box className={styles.summaryBox}>
            <Box className={styles.billDetails}>
              <Typography variant="h6">Bill Details</Typography>
              <Typography className={styles.list}>
                <span>Item Total</span>
                <span className={styles.oldPrice}>
                  ₹{billDetails?.TotalMrp?.toFixed(2) || "0.00"}
                </span>
              </Typography>
              <Typography className={styles.list}>
                <span>Discount</span>{" "}
                <span>₹{billDetails?.Discount?.toFixed(2) || "0.00"}</span>
              </Typography>
              <Typography className={styles.list}>
                <span>SubTotal</span>
                <span>₹{billDetails?.Price?.toFixed(2) || "0.00"}</span>
              </Typography>
              {billDetails?.PromoDiscount && (
                <Typography className={styles.list}>
                  <span>Promo Discount</span>
                  <span>₹{(billDetails?.PromoDiscount).toFixed(2)}</span>
                </Typography>
              )}
              <Typography className={styles.list}>
                <span>Shipping</span>{" "}
                <span>
                  {billDetails?.Shipping == 0
                    ? "free"
                    : `₹${billDetails?.Shipping?.toFixed(2)}` || "0.00"}
                </span>
              </Typography>
              <Divider style={{ background: "black" }} />
              <Typography className={styles.list}>
                <span>Grand Total</span>
                <span className={styles.totalPrice}>
                  ₹{billDetails?.TotalPrice?.toFixed(2) || "0.00"}
                </span>
              </Typography>
            </Box>
            <Divider />
            <Button
              variant="contained"
              onClick={(e) => handlePlaceOrder(e)}
              className={styles.placeOrderButton}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} className={styles.buttonProgress} />
              ) : (
                "Place Order"
              )}
            </Button>
          </Box>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PaymentPage;
