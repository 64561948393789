import React from 'react';
import styles from './TermsAndCondition.module.css';
import NavBar from '../../NavBar/NavBar';
import { Helmet } from "react-helmet";
import Footer from '../../Footer/Footer';

const TermsAndConditions = () => {
  return (
    <>
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <title>zuluresh | Terms and Conditions</title>
        <meta
          name="description"
          content="Read the terms and conditions for using Zuluresh, the online platform for ordering fresh and high-quality meat and fish products."
        />
        <link rel="canonical" href="https://www.zuluresh.com/terms-and-conditions" />
        <meta name="title" content="zuluresh Terms and Conditions" />
        <meta name="keyword" content="zuluresh, terms and conditions, meat, fish, online order" />
        {/* Schema Markup */}
        <script type="application/ld+json">
          {`
          {
            "@context": "http://schema.org",
            "@type": "WebSite",
            "name": "zuluresh",
            "url": "https://www.zuluresh.com/terms-and-conditions",
            "description": "Read the terms and conditions for using Zuluresh, the online platform for ordering fresh and high-quality meat and fish products.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": "https://www.zuluresh.com/terms-and-conditions",
              "query-input": "required name=terms-and-conditions"
            }
          }
          `}
        </script>
        {/* End Schema Markup */}
        {/* Google tag (gtag.js) */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-SJPR5MYB8S"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-SJPR5MYB8S');
          `}
        </script>
    {/* Meta Pixel Code */}
    <script>
      {`
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '2583408375194861');
      fbq('track', 'PageView');
      `}
    </script>
    <noscript>
      {`
      <img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=2583408375194861&ev=PageView&noscript=1"/>
      `}
    </noscript>
    {/* End Meta Pixel Code */}
    {/* Hotjar Tracking Code for https://www.zuluresh.com/ */}
    <script>
      {`
      (function (h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: 5054101, hjsv: 6 };
        a = o.getElementsByTagName("head")[0];
        r = o.createElement("script");
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
      `}
    </script>
      </Helmet>
      <NavBar />
      <div className={styles.container}>
        <h1 className={styles.header}>Terms and Conditions</h1>
        <p className={styles.intro}>
          Welcome to Zuluresh! These terms and conditions outline the rules and regulations for the use of our website.
        </p>

        <section className={styles.section}>
          <h2 className={styles.subHeader}>Acceptance of Terms</h2>
          <p>
            By accessing this website, you accept these terms and conditions in full. Do not continue to use Zuluresh if you do not agree to all the terms and conditions stated on this page.
          </p>
        </section>

        <section className={styles.section}>
          <h2 className={styles.subHeader}>Changes to Terms</h2>
          <p>
            We may update these terms and conditions from time to time. Any changes will be posted on this page, and your continued use of the website constitutes your acceptance of the new terms.
          </p>
        </section>

        <section className={styles.section}>
          <h2 className={styles.subHeader}>User Responsibilities</h2>
          <p>
            As a user of this website, you agree to use it only for lawful purposes and in a way that does not infringe the rights of, restrict, or inhibit anyone else's use of the website.
          </p>
        </section>

        <section className={styles.section}>
          <h2 className={styles.subHeader}>Intellectual Property</h2>
          <p>
            The content, layout, design, data, databases, and graphics on this website are protected by intellectual property laws. You may not reproduce, distribute, or publish any material from this website without prior written consent from us.
          </p>
        </section>

        <section className={styles.section}>
          <h2 className={styles.subHeader}>Limitation of Liability</h2>
          <p>
            Zuluresh will not be liable for any damages arising out of or in connection with the use of this website. This includes, without limitation, direct loss, loss of business or profits, damage caused to your computer, software, systems, and programs, and the data thereon or any other direct or indirect, consequential, and incidental damages.
          </p>
        </section>

        <section className={styles.section}>
          <h2 className={styles.subHeader}>Governing Law</h2>
          <p>
            These terms and conditions are governed by and construed in accordance with the laws of [Your Country], and you irrevocably submit to the exclusive jurisdiction of the courts in that state or location.
          </p>
        </section>

        <section className={styles.section}>
          <h2 className={styles.subHeader}>Contact Us</h2>
          <p>
            If you have any questions or concerns about these terms and conditions, please contact us at <a href="mailto:support@zuluresh.com" className={styles.link}>support@zuluresh.com</a>.
          </p>
        </section>
      </div>
      <Footer/>
    </>
  );
};

export default TermsAndConditions;
