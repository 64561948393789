import React, { useState, useEffect } from "react";
import styles from "./CheckoutPage.module.css";
import NavBar from "../../NavBar/NavBar";
import {
  Box,
  Button,
  Typography,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomButton from "../../CustomButton/CustomButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { Helmet } from "react-helmet";
import OrderSummaryModal from "../../OrderSummaryModal/OrderSummaryModal";
import {
  AddtoCart,
  getAllAddress,
  getCheckoutData,
  getCouponCode,
  removeProductfromCart,
  removetoCart,
} from "../../../Api/Api";
import Cookies from "js-cookie";
import Footer from "../../Footer/Footer";
import axios from "axios";

function CheckoutPage() {
  const [expanded, setExpanded] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(0);
  const [billDetails, setBillingDetails] = useState([]);
  const [timeSlots, setTimeSlots] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [canContinue, setCanContinue] = useState(false);
  const [promoCode, setPromoCode] = useState("");


  useEffect(() => {
    sessionStorage.setItem("checkout_Status", false);
    function getToken() {
      return document.cookie.replace(
        /(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/,
        "$1"
      );
    }
    const token = getToken();
    if (!token) {
      window.location.href = "/login";
    }
  }, []);

  useEffect(() => {
    const defaultAddress = JSON.parse(sessionStorage.getItem("defaultAddress"));
    const handleAllAddress = async () => {
      try {
        const response = await getAllAddress();
        const selectedAddresss = defaultAddress
          ? defaultAddress
          : response?.data[0];
        setSelectedAddress(selectedAddresss);
        sessionStorage.setItem(
          "defaultAddress",
          JSON.stringify(response?.data[0])
        );
        setUpdate(update + 6)
      } catch (error) {
        console.log(error);
      }
    };
    handleAllAddress();

    const selectedSlot = JSON.parse(sessionStorage.getItem("selectedSlot"));
    setTimeSlots(selectedSlot);

    const storedCartItems = JSON.parse(sessionStorage.getItem("cart")) || [];
    setCartItems(storedCartItems);

    const token = getToken();
    if (token) {
      handleCheckout(promoCode);
    }

    validateContinueButton(selectedSlot, defaultAddress);
  }, []);

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleCheckout = async () => {
    setLoading(true);
    try {
      const response = await getCheckoutData();
      setCartItems(response.data.productsData);
      if(response.data.productsData.length == 0){
        window.location.href="/"
      }
      setBillingDetails(response.data);
      setUpdate(update + 5);
      setLoading(false);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const { response } = error;
        // Get the error message from the response or use a default message
        const errorMessage = response?.data?.message ;
        //  alert(errorMessage)
      } 
      setLoading(false);
    }
  };



  function getToken() {
    return document.cookie.replace(
      /(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/,
      "$1"
    );
  }

  const handleAddToCartInBackend = async (id) => {
    setLoading(true);
    try {
      await AddtoCart(id);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    } finally {
      handleCheckout();
    }
  };

  const handleRemoveProductFromCartInBackend = async (id) => {
    setLoading(true);
    try {
      await removeProductfromCart(id);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    } finally {
      handleCheckout();
    }
  };

  const handleRemoveFromCartInBackend = async (id) => {
    setLoading(true);
    try {
      await removetoCart(id);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    } finally {
      handleCheckout();
    }
  };

  const handleQuantityChange = (index, delta) => {
    const newCartItems = [...cartItems];
    const item = newCartItems[index];
    const id = item?.Product_id ? item?.Product_id : item?.id;

    if (id) {
      item.quantity += delta;
      if (item.quantity < 1) {
        item.quantity = 1;
      }
      setCartItems(newCartItems);

      const token = getToken();
      if (token) {
        if (delta > 0) {
          handleAddToCartInBackend(id);
        } else {
          handleRemoveFromCartInBackend(id);
        }
      }
    }
  };

  const handleRemoveItem = (id) => {
    const newCartItems = cartItems.filter((item) => item.id !== id);
    setCartItems(newCartItems);
    const token = getToken();
    if (token) {
      handleRemoveProductFromCartInBackend(id);
    }
  };

  const totalAmount = cartItems.reduce(
    (acc, item) => acc + item.price * item.quantity,
    0
  );

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const validateContinueButton = (selectedSlot, defaultAddress) => {
    if (
      selectedSlot &&
      selectedSlot.day &&
      defaultAddress 
    ) {
      setCanContinue(true);
    } else {
      setCanContinue(false);
    }
  };

  useEffect(() => {
    validateContinueButton(timeSlots, selectedAddress);
  }, [timeSlots, selectedAddress]);

  const handlePromoCodeChange = (event) => {
    setPromoCode(event.target.value);
  };


  const applyPromoCode = () => {
    handleCouponCode(promoCode);
  };
  const handleCouponCode= async(promoCode = "")=>{
    setLoading(true);
    try {
      const response = await getCouponCode(promoCode.toUpperCase());
      handleCheckout()
      setUpdate(update + 5);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }



  return (
    <>
      <OrderSummaryModal open={isModalOpen} onClose={handleClose} />
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <title>zuluresh | Buy Fresh & High Quality Meat at Best Price on Zuluresh</title>
        <meta
          name="description"
          content="Order meat online in just a few clicks.Zuluresh offers 100% fresh & best quality Chicken, Fish/Prawns and Mutton products online."
        />
        <link rel="canonical" href="https://www.zuluresh.com/checkout" />
        <meta name="title" content="zuluresh" />
        <meta name="keyword" content="zuluresh | Buy Fresh & High Quality Meat at Best Price on Zuluresh" />
        <script type="application/ld+json">
          {`
          {
            "@context": "http://schema.org",
            "@type": "WebSite",
            "name": "zuluresh",
            "url": "https://www.zuluresh.com/",
            "description": "Order meat online in just a few clicks.Zuluresh offers 100% fresh & best quality Chicken, Fish/Prawns and Mutton products online.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": "https://www.zuluresh.com/",
              "query-input": "required name=search_term_string"
            }
          }
          `}
        </script>
      </Helmet>
      <NavBar />
      <div className={styles.main}>
        {loading && "Loading.."}
        <div
          style={{ display: "flex", padding: "20px" }}
          className={styles.custom_btn}
        >
          <CustomButton active={true} label="Delivery Summary" />
          <div className={styles.custom_line}></div>
          <CustomButton active={false} label="Delivery Summary" />
        </div>
        <br />
        <div className={styles.container}>
          <div className={styles.left_box}>
            {cartItems.length === 0 && <p>No Products</p>}
            <List>
              {cartItems.map((item, index) => (
                <ListItem
                  key={item?.Product_id ? item?.Product_id : item?.id}
                  divider
                  className={styles.list_item}
                >
                  <img
                    src={
                      item?.Product_image ? item?.Product_image : item?.image
                    }
                    alt={item?.Product_title ? item?.Product_title : item?.name}
                    className={styles.image}
                    title="Product"
                    loading="lazy"
                    width="200px"
                    height="100px"
                  />
                  <Box width="100%">
                    <Box display="flex" justifyContent="space-between">
                      <ListItemText
                        primary={
                          item?.Product_title ? item?.Product_title : item?.name
                        }
                        secondary={`${
                          item?.Product_unit ? item?.Product_unit : item?.unit
                        }${
                          item?.Product_measureUnit
                            ? item?.Product_measureUnit
                            : item?.measureunit
                        }`}
                      />
                      <IconButton
                        sx={{ fontSize: 16 }}
                        onClick={() =>
                          handleRemoveItem(
                            item?.Product_id ? item?.Product_id : item?.id
                          )
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{ display: "flex", gap: "10px" }}
                    >
                      <p style={{ textDecoration: "line-through" }}>
                        ₹
                        {item?.Product_MRP
                          ? item?.Product_MRP
                          : item.originalPrice.toFixed(2)}
                      </p>
                      <p style={{ color: "#941a49" }}>{item.discount}</p>
                    </Typography>
                    <Typography className={styles.quantity_boxes}>
                      <p style={{ color: "#941a49" }}>
                        ₹
                        {(item?.Product_price
                          ? item?.Product_price
                          : item?.price
                        ).toFixed(2)}
                      </p>
                      <Box
                        display="flex"
                        alignItems="center"
                        className={styles.quntity_box}
                      >
                        <IconButton
                          sx={{ fontSize: 16 }}
                          onClick={() => handleQuantityChange(index, -1)}
                        >
                          <RemoveIcon />
                        </IconButton>
                        <Typography>
                          {item?.Product_quantity
                            ? item?.Product_quantity
                            : item?.quantity}
                        </Typography>
                        <IconButton
                          sx={{ fontSize: 16 }}
                          onClick={() => handleQuantityChange(index, 1)}
                        >
                          <AddIcon />
                        </IconButton>
                      </Box>
                    </Typography>
                  </Box>
                </ListItem>
              ))}
            </List>
            <Box className={styles.deliverySlot}>
              {timeSlots ? (
                <Typography className={styles.discounts}>
                  {timeSlots?.day}
                  <span>
                    <span> {timeSlots?.startTime}</span>-
                    <span>{timeSlots?.endTime}</span>
                  </span>
                </Typography>
              ) : (
                <p>Please Select Slot</p>
              )}
              <IconButton onClick={handleOpen}>
                <ExpandMoreIcon />
              </IconButton>
            </Box>
          </div>
          <Box className={styles.summaryBox}>
            <Box className={styles.billDetails}>
              <Typography variant="h6">Bill Details</Typography>
              <Typography className={styles.list}>
                <span>Item Total</span>
                <span className={styles.oldPrice}>
                  <span>
                    {" "}
                    ₹
                    {billDetails?.TotalMrp
                      ? billDetails?.TotalMrp.toFixed(2)
                      : totalAmount.toFixed(2)}
                  </span>
                </span>
              </Typography>
              <Typography className={styles.list}>
                <span>Discount</span>{" "}
                <span>
                  ₹
                  {billDetails?.Discount ? billDetails?.Discount.toFixed(2) : 0}
                </span>
              </Typography>

              <Typography className={styles.list}>
                <span>SubTotal</span>
                <span>
                  ₹
                  {(billDetails?.Price
                    ? billDetails?.Price
                    : totalAmount
                  ).toFixed(2)}
                </span>
              </Typography>
              {billDetails?.PromoDiscount && (
                <Typography className={styles.list}>
                  <span>Promo Discount</span>
                  <span>₹{(billDetails?.PromoDiscount).toFixed(2)}</span>
                </Typography>
              )}

              <Typography className={styles.list}>
                <span>Shipping</span>{" "}
                <span>
                  ₹
                  {billDetails?.Shipping == 0   ? "free" : billDetails?.Shipping?.toFixed(2)}
                </span>
              </Typography>
              <Divider style={{ background: "black" }} />
              <Typography className={styles.list}>
                <span>Grand Total</span>
                <span className={styles.totalPrice}>
                  ₹
                  {(billDetails?.TotalPrice
                    ? billDetails?.TotalPrice
                    : totalAmount
                  ).toFixed(2)}
                </span>
              </Typography>
            </Box>
            <Box className={styles.promoCodeBox}>
              <TextField
                label="Promo Code"
                variant="outlined"
                value={promoCode}
                onChange={handlePromoCodeChange}
                className={styles.promoCodeInput}
              />
              <Button
                variant="contained"
                onClick={applyPromoCode}
                className={styles.applyButton}
              >
                Apply
              </Button>
            </Box>
            <Box className={styles.address_box}>
              <Typography variant="h6" className={styles.delivery_address}>
                Delivery address
                <Button
                  className={styles.changeButton}
                  onClick={() => (window.location.href = "/address")}
                >
                  CHANGE
                </Button>
              </Typography>
              {selectedAddress ? (
                <Typography>
                  {selectedAddress?.houseFlatNo} ,{selectedAddress?.blockName} ,
                  {selectedAddress?.street},{selectedAddress?.locality} ,
                  {selectedAddress?.landMark} ,{selectedAddress?.pinCode}
                </Typography>
              ) : (
                <Typography>Please Select Address</Typography>
              )}
            </Box>
            <Divider />
            <Button
              variant="contained"
              className={styles.continueButton}
              disabled={!canContinue}
              onClick={() =>window.location.href = "/payment"}
            >
              CONTINUE
            </Button>
          </Box>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CheckoutPage;
