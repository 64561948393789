import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import styles from './AccordionComponent.module.css';

const faqs = [
  {
    question: "What types of meat do you sell?",
    answer: "We offer a variety of meats including mutton, fish, chicken, and more."
  },
  {
    question: "Is the meat fresh?",
    answer: "Yes, all our meat is freshly cut and packed to ensure maximum freshness."
  },
  {
    question: "How is the meat packaged?",
    answer: "The meat is hygienically packed in vacuum-sealed packages to retain freshness."
  },
  {
    question: "Do you offer home delivery?",
    answer: "Yes, we deliver fresh meat right to your doorstep."
  },
  {
    question: "How can I place an order?",
    answer: "You can place an order through our website or mobile app."
  },

];

export default function AccordionComponent() {
  return (
    <div className={styles.root}>
      {faqs.map((faq, index) => (
        <Accordion key={index} className={styles.accordionRoot}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={styles.expandIcon} />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
            className={styles.accordionSummaryRoot}
          >
            <Typography>{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {faq.answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
