import React from "react";
import { Facebook, Twitter, Instagram, YouTube } from "@mui/icons-material";
import styles from "./Footer.module.css";
import logo from "../Images/zuluresh logo.png";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.section}>
        <a className={styles.logo_box} href="/">
          <img
            src={logo}
            alt="zuluresh logo"
            title="zuluresh logo"
            loading="lazy"
            width="auto"
            height="auto"
            className={styles.logo}
          />
        </a>
        <address className={styles.address}>
          Zuluresh Foods Private Limited
        </address>
      </div>
      <div className={styles.section}>
        <nav className={styles.nav}>
          <a href="/faqs">FAQ</a>
          <a href="/all-orders">Orders</a>
          <a href="/terms-and-conditions">Terms and Conditions</a>
          <a href="/privacy-policy">Privacy Policy</a>
        </nav>
        <div className={styles.apps}>
          <a
            href="https://play.google.com/store/apps/details?id=com.zuluresh"
            target="_blank"
          >
            <img
              src="https://www.freshtohome.com/skin/frontend/default/theme659/images/playstore_badge.png"
              height="auto"
              width="auto"
              title="Zuluresh"
              alt="Google Play"
              loading="lazy"
            />
          </a>
        </div>
        <p className={styles.description}>
          Order your daily Fish, Poultry, and Mutton. Get it delivered at your
          doorsteps.
        </p>
      </div>
      <div className={styles.section}>
        <div className={styles.contact}>
          <p>Contact Us</p>
          <p>+91-9910995875</p>
          <p>customercare@zuluresh.com</p>
        </div>
        <div className={styles.social}>
          <p>Follow Us</p>
          <div className={styles.icons}>
            <a
              href="https://www.linkedin.com/company/zuluresh/"
              target="_blank"
            >
              {" "}
              <LinkedInIcon />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100094068281332"
              target="_blank"
            >
              {" "}
              <Facebook />
            </a>
            <a href="https://twitter.com/zuluresh" target="_blank">
              <Twitter />
            </a>
            <a href="https://www.instagram.com/zuluresh/" target="_blank">
              <Instagram />
            </a>
            <a
              href="https://www.youtube.com/channel/UCbrYFnrw8VB1K7I88CO_0ng"
              target="_blank"
            >
              <YouTube />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
