// src/components/ProductCard/ProductCard.js
import React from "react";
import styles from "./ProductCard.module.css";
import { useRecoilState } from "recoil";
import { openUpdateDrawer, updateCart } from "../Recoil/Recoil";
import { AddtoCart } from "../../Api/Api";

const ProductCard = ({
  stock,
  measureunit,
  unit,
  image,
  name,
  originalPrice,
  discount,
  price,
  specialOffer,
  id,
}) => {
  // Format price to two decimal places
  const [udpateCart, setUpdateCart] = useRecoilState(updateCart);

  const formatPrice = (price) => parseFloat(price).toFixed(2);

  
  const handleAddToCartInBeckend = async (id) => {
    try {
      const response = await AddtoCart(id);
    } catch (error) {
      console.log(error);
    }
  };

  // Handle add to cart
  const handleAddToCart = (id) => {
    function getToken() {
      return document.cookie.replace(
        /(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/,
        "$1"
      );
    }
  
    // Retrieve token
    const token = getToken();
    if(token){
      handleAddToCartInBeckend(id);
    }
    // Get the current cart from session storage or initialize an empty array
    const cart = JSON.parse(sessionStorage.getItem("cart")) || [];

    // Check if the product is already in the cart
    const productIndex = cart.findIndex((product) => product.id === id);

    if (productIndex !== -1) {
      // Product is already in the cart, increase the quantity
      cart[productIndex].quantity += 1;
    } else {
      // Product is not in the cart, add it with quantity 1
      cart.push({
        id,
        name,
        image,
        originalPrice,
        discount,
        price,
        measureunit,
        unit,
        quantity: 1,
      });
    }

    // Save the updated cart back to session storage
    sessionStorage.setItem("cart", JSON.stringify(cart));
    setUpdateCart(udpateCart + 3);
  };

  return (
    <>
      {stock === "Out Of Stock" ? (
        <div className={styles.outOfStock}>
          <div className={styles.outOfStock_container}>
            <p>Out Of Stock</p>
          </div>
          <div className={styles.card}>
            <img
              src={image}
              alt={name}
              className={styles.image}
              title="Product"
              loading="lazy"
              width="auto"
              height="auto"
            />
            <div className={styles.details}>
              <p className={styles.name}>{name}</p>
              <div style={{ display: "flex", gap: "5px" }}>
                <p className={styles.originalPrice}>
                  ₹ {formatPrice(originalPrice)}
                </p>
                <p className={styles.discount}>{discount}</p>
              </div>
              <div className={styles.total_price}>
                <p className={styles.price}>
                  ₹ {formatPrice(price)} /{" "}
                  <span>
                    {unit}
                    {measureunit}
                  </span>
                </p>
                <button className={styles.addButton} disabled>
                  Add
                </button>
              </div>
              {/* {specialOffer && <p className={styles.specialOffer}>{specialOffer}</p>} */}
            </div>
          </div>
        </div>
      ) : (
        <div
          className={styles.card}
          onClick={() =>
            (window.location.href = `/product/${name.replace(/\s+/g, "-")}`)
          }
        >
          <img
            src={image}
            alt={name}
            className={styles.image}
            title="Product"
            loading="lazy"
            width="auto"
            height="auto"
          />
          <div className={styles.details}>
            <p className={styles.name}>{name}</p>
            <div style={{ display: "flex", gap: "5px" }}>
              <p className={styles.originalPrice}>
                ₹ {formatPrice(originalPrice)}
              </p>
              <p className={styles.discount}>{discount}</p>
            </div>
            <div className={styles.total_price}>
              <p className={styles.price}>
                ₹ {formatPrice(price)} /{" "}
                <span>
                  {unit}
                  {measureunit}
                </span>
              </p>
              <button
                className={styles.addButton}
                onClick={(e) => {
                  e.stopPropagation();
                  handleAddToCart(id);
                }}
              >
                Add
              </button>
            </div>
            {/* {specialOffer && <p className={styles.specialOffer}>{specialOffer}</p>} */}
          </div>
        </div>
      )}
    </>
  );
};

export default ProductCard;
