import React, { useState } from "react";
import { TextField, Button, Container, Typography, Box, Radio, RadioGroup, FormControlLabel, FormLabel } from "@mui/material";
import axios from "axios";
import styles from "./AddAddress.module.css";
import NavBar from "../../NavBar/NavBar";
import { Helmet } from "react-helmet";
import Footer from "../../Footer/Footer";

const AddAddress = () => {
  const [fullName, setFullName] = useState("");
  const [street, setStreet] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [blockName, setBlockName] = useState("");
  const [landmark, setLandmark] = useState("");
  const [locality, setLocality] = useState("");
  const [pincode, setPincode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [addressType, setAddressType] = useState("");
  const [errors, setErrors] = useState({});

  const validate = () => {
    const newErrors = {};
    if (!fullName) newErrors.fullName = "Full name is required";
    if (!street) newErrors.street = "Street is required";
    if (!houseNumber) newErrors.houseNumber = "House/Flat number is required";
    if (!locality) newErrors.locality = "Locality is required";
    if (!pincode) {
      newErrors.pincode = "Pincode is required";
    } else if (!/^\d{6}$/.test(pincode)) {
      newErrors.pincode = "Please enter a valid 6-digit pincode";
    }
    if (!phoneNumber) {
      newErrors.phoneNumber = "Mobile number is required";
    } else if (!/^\d{10}$/.test(phoneNumber)) {
      newErrors.phoneNumber = "Please enter a valid 10-digit mobile number";
    }
    if (!addressType) newErrors.addressType = "Address type is required";
    return newErrors;
  };

  const handleAddAddress = async () => {
        // Function to retrieve token from cookies
        function getToken() {
            return document.cookie.replace(
              /(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/,
              "$1"
            );
          }
        
          // Retrieve token
          const token = getToken();
    const newErrors = validate();
    if (Object.keys(newErrors).length === 0) {
      try {
        const headers = {
            "x-auth-token": token, // Pass the token in the header
            "Content-Type": "application/json", // Set content type to JSON
          };
        const response = await axios.post('https://www.backend.zuluresh.com/user/address/addAddress', {
          name: fullName,
          phoneNo: phoneNumber,
          houseFlatNo: houseNumber,
          blockName: blockName,
          street: street,
          landMark: landmark,
          pinCode: pincode,
          locality: locality,
          saveAddressAs: addressType
        },{headers});
        alert("Address updated successfully!");
        window.location.href = "/address";
      } catch (error) {
        if (axios.isAxiosError(error)) {
            // Axios error (HTTP error)
            const { response } = error;
            // Set the error message
            const errorMessage = response.data.message;
            // alert(errorMessage);
            // Log the error message as a string
            console.error("Axios Error:", errorMessage);
            alert(errorMessage)
          } else {
            // Network error (e.g., no internet connection)
            // alert("Something went wrong");
            console.error("Network Error:", error.message);
          }
      }
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <>
       <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <title>zuluresh | Buy Fresh & High Quality Meat at Best Price on Zuluresh</title>
        <meta
          name="description"
          content="Order meat online in just a few clicks.Zuluresh offers 100% fresh & best quality Chicken, Fish/Prawns and Mutton products online."
        />
        <link rel="canonical" href="https://www.zuluresh.com/add-address" />
        <meta name="title" content="zuluresh" />
        <meta name="keyword" content="zuluresh | Buy Fresh & High Quality Meat at Best Price on Zuluresh" />
        {/* Schema Markup */}
        <script type="application/ld+json">
          {`
          {
            "@context": "http://schema.org",
            "@type": "WebSite",
            "name": "zuluresh",
            "url": "https://www.zuluresh.com/",
            "description": "Order meat online in just a few clicks.Zuluresh offers 100% fresh & best quality Chicken, Fish/Prawns and Mutton products online.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": "https://www.zuluresh.com/",
              "query-input": "required name=search_term_string"
            }
          }
          `}
        </script>
        {/* End Schema Markup */}
        {/* Google tag (gtag.js) */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-SJPR5MYB8S"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-SJPR5MYB8S');
          `}
        </script>
        {/* Meta Pixel Code */}
        <script>
          {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '940817377734374');
          fbq('track', 'PageView');
          `}
        </script>
        <noscript>
          {`
          <img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=940817377734374&ev=PageView&noscript=1" />
          `}
        </noscript>
        {/* End Meta Pixel Code */}
      </Helmet>
      <NavBar />
      <Container Width="80%" className={styles.root}>
        <Typography variant="h5" gutterBottom>
          Add Address
        </Typography>
        <form className={styles.form} noValidate autoComplete="off">
          <TextField
            className={styles.input}
            label="Full Name*"
            variant="outlined"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            error={!!errors.fullName}
            helperText={errors.fullName}
          />
          <TextField
            className={styles.input}
            label="Street*"
            variant="outlined"
            value={street}
            onChange={(e) => setStreet(e.target.value)}
            error={!!errors.street}
            helperText={errors.street}
          />
          <TextField
            className={styles.input}
            label="House/Flat Number*"
            variant="outlined"
            value={houseNumber}
            onChange={(e) => setHouseNumber(e.target.value)}
            error={!!errors.houseNumber}
            helperText={errors.houseNumber}
          />
          <TextField
            className={styles.input}
            label="Block Name"
            variant="outlined"
            value={blockName}
            onChange={(e) => setBlockName(e.target.value)}
          />
          <TextField
            className={styles.input}
            label="Landmark*"
            variant="outlined"
            value={landmark}
            onChange={(e) => setLandmark(e.target.value)}
          />
          <TextField
            className={styles.input}
            label="Locality*"
            variant="outlined"
            value={locality}
            onChange={(e) => setLocality(e.target.value)}
            error={!!errors.locality}
            helperText={errors.locality}
          />
          <TextField
            className={styles.input}
            label="Pincode*"
            variant="outlined"
            value={pincode}
            onChange={(e) => setPincode(e.target.value)}
            error={!!errors.pincode}
            helperText={errors.pincode}
          />
          <TextField
            className={styles.input}
            label="Mobile Number*"
            variant="outlined"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber}
          />
          <Box className={styles.radioGroup}>
            <FormLabel component="legend">Address Type</FormLabel>
            <RadioGroup
              row
              value={addressType}
              onChange={(e) => setAddressType(e.target.value)}
            >
              <FormControlLabel value="Home" control={<Radio />} label="Home" />
              <FormControlLabel value="Work" control={<Radio />} label="Work" />
              <FormControlLabel value="Other" control={<Radio />} label="Other" />
            </RadioGroup>
            {errors.addressType && <p className={styles.errorText}>{errors.addressType}</p>}
          </Box>
        </form>
        <br />
        <Button
          className={styles.updateButton}
          variant="contained"
          onClick={handleAddAddress}
        >
          Save Address
        </Button>
      </Container>
      <Footer />
    </>
  );
};

export default AddAddress;
