// src/components/PopularSearches.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./PopularSearches.module.css";


const PopularSearches = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.get(
        "https://www.backend.zuluresh.com/admin/product/getAllproduct"
      );
      const products = response.data.data;
      categorizeProducts(products);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const categorizeProducts = (products) => {
    const categoryMap = {};

    products.forEach((product) => {
      const categoryName = product.category;
      if (!categoryMap[categoryName]) {
        categoryMap[categoryName] = [];
      }
      categoryMap[categoryName].push(product.title);
    });

    setCategories(Object.entries(categoryMap));
  };

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <h5>POPULAR SEARCHES</h5>
        {categories.map(([categoryName, products]) => (
          <div key={categoryName} className={styles.category}>
            <h6>{categoryName}</h6>
            <div className={styles.productList}>
              {products.map((product, index) => (
                <span key={index} className={styles.product}>
                  <a href={`/product/${product.replace(/\s+/g, "-")}`}>
                    {" "}
                    {product}
                  </a>
                  {index < products.length - 1}
                </span>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PopularSearches;
