import React, { useEffect, useState } from "react";
import style from "./AddressCard.module.css";
import NavBar from "../NavBar/NavBar";
import { Divider } from "@mui/material";
import { deleteAddress, getAllAddress } from "../../Api/Api";

function AddressCard() {
  const [addressData, setAddressData] = useState([]);
  const [defaultAddress, setDefaultAddress] = useState(null);

  useEffect(() => {
    handleAllAddress();
  }, []);

  useEffect(() => {
    if (addressData.length > 0) {
      const storedAddress = sessionStorage.getItem("defaultAddress");
      if (storedAddress) {
        setDefaultAddress(JSON.parse(storedAddress));
      } else {
        setDefaultAddress(addressData[0]);
        sessionStorage.setItem("defaultAddress", JSON.stringify(addressData[0]));
      }
    }
  }, [addressData]);

  const handleAllAddress = async () => {
    try {
      const response = await getAllAddress();
      setAddressData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteAddress = async (id) => {
    try {
      await deleteAddress(id);
      handleAllAddress();
    } catch (error) {
      console.log(error);
    }
  };

  const handleSetDefault = (address) => {
    setDefaultAddress(address);
    sessionStorage.setItem("defaultAddress", JSON.stringify(address));
  };

  return (
    <div className={style.main}>
      <NavBar />
      <h2>Address</h2>
      <div className={style.container}>
        <div className={style.address_container}>
          {addressData?.map((address, index) => (
            <div
              className={`${style.addressCard} ${defaultAddress?._id === address._id ? style.defaultCard : ""}`}
              key={index}
              onClick={() => handleSetDefault(address)}
            >
              <h2>
                {address.saveAddressAs}{" "}
                {defaultAddress?._id === address._id && (
                  <span className={style.default}>By Default</span>
                )}
              </h2>
              <p>{address.name}</p>
              <p>
                {address.houseFlatNo}, {address.blockName}
              </p>
              <p>{address.street}</p>
              <p>
                {address.locality}, {address.pinCode}
              </p>
              <p>{address.phoneNo}</p>
              <br />
              <Divider style={{ backgroundColor: "white" }} />
              <div className={style.actions}>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    window.location.href = `/update-address/${address._id}`;
                  }}
                >
                  Edit
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteAddress(address._id);
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>

        <div className={style.Add_actions}>
          <button
            className={style.newAddress}
            onClick={() => (window.location.href = "/add-address")}
          >
            New Address
          </button>
          <button className={style.continue}  onClick={() => (window.location.href = "/checkout")}>Continue</button>
        </div>
      </div>
    </div>
  );
}

export default AddressCard;
