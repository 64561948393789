import axios from "axios";
import Cookies from "js-cookie";

const BASE_URL = "https://www.backend.zuluresh.com";
// const BASE_URL = "https://www.backend.zuluresh.com";

// LOG IN

export const login = async (phoneNumber) => {
  try {
    const response = await axios.post(`${BASE_URL}/user/auth/signUp`, {
      number: phoneNumber,
    });
    const { status, message, data, token } = response.data;
    // console.log(response);
    if (status) {
      Cookies.set("token", token, { expires: 7 }); // Stores the token in a cookie for 7 days
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios error (HTTP error)
      const { response } = error;
      // Set the error message
      const errorMessage = response.data.message;
      // alert(errorMessage);
      // Log the error message as a string
    } else {
      // Network error (e.g., no internet connection)
      // alert("Something went wrong");
    }
  }
};

export const verifyUser = async (otp, phoneNumber) => {
  // Retrieve and parse the cart data from session storage
  const cartData = JSON.parse(sessionStorage.getItem("cart")) || [];
  const location = JSON.parse(sessionStorage.getItem("selectedLocation"));

  try {
    // Map the cart items to the required format
    const items = cartData.map((item) => ({
      productId: item.id, // Use the correct key for the product ID
      quantity: item.quantity, // Use the correct key for the quantity
    }));
    // Send the POST request to the verification endpoint
    const response = await axios.post(`${BASE_URL}/user/auth/verifiction`, {
      number: phoneNumber,
      otp: otp,
      items: items,
      pincodeData: {
        pincode: location.pincode,
        state: location?.state,
        district: location?.district,
        areaName: location?.areaName,
      },
    });

    // Destructure the response data
    const { status, message, token } = response.data;

    // Check if the status is true and handle accordingly
    if (status) {
      // Store the token in a cookie for 7 days
      Cookies.set("token", token, { expires: 7 });
      return { success: true, message: "Verification successful" };
    } else {
      return { success: false, message: message || "Verification failed" };
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const { response } = error;
      // Get the error message from the response or use a default message
      const errorMessage = response?.data?.message || "Verification failed";
      return { success: false, message: errorMessage };
    } else {
      // Handle other errors
      return { success: false, message: "Something went wrong" };
    }
  }
};

export const getAllPincode = async () => {
  try {
    const response = await axios.get(
      `${BASE_URL}/admin/pincodeLocation/getAllPincode`,
      {}
    );
    // console.log(response, "Response from axios");

    // Directly return the data from axios response
    return response.data;
  } catch (error) {
    console.error("Error in getAllCategory function:", error);
  }
};

export const getAllCategory = async () => {
  try {
    const response = await axios.get(
      `${BASE_URL}/admin/categoryAndSubCategory/getAllCategory`,
      {}
    );
    // console.log(response, "Response from axios");

    // Directly return the data from axios response
    return response.data;
  } catch (error) {
    console.error("Error in getAllCategory function:", error);
  }
};

//GetCheckoutData

export const getCheckoutData = async () => {
  const promoCode = JSON.parse(sessionStorage.getItem("couponCode"));
  function getToken() {
    return document.cookie.replace(
      /(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/,
      "$1"
    );
  }

  // Retrieve token
  const token = getToken();
  try {
    const headers = {
      "x-auth-token": token, // Pass the token in the header
      "Content-Type": "application/json", // Set content type to JSON
    };
    const url = promoCode
      ? `${BASE_URL}/user/cart/checkOut?promoCode=${promoCode}`
      : `${BASE_URL}/user/cart/checkOut`;

    const response = await axios.get(url, { headers });
    sessionStorage.setItem(
      "cart",
      JSON.stringify(response?.data?.data?.productsData) || []
    );
    // console.log(response, "Response from api");

    // Directly return the data from axios response
    return response.data;
  } catch (error) {
    console.error("Error in getAllCategory function:", error);
  }
};

export const getCouponCode = async (promoCode = "") => {
  function getToken() {
    return document.cookie.replace(
      /(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/,
      "$1"
    );
  }

  // Retrieve token
  const token = getToken();
  try {
    const headers = {
      "x-auth-token": token, // Pass the token in the header
      "Content-Type": "application/json", // Set content type to JSON
    };

    // Construct URL with promo code if it exists
    const url = `${BASE_URL}/user/cart/checkOut?promoCode=${promoCode}`;

    const response = await axios.get(url, { headers });
    if (response.status) {
      sessionStorage.setItem("couponCode", JSON.stringify(promoCode));
      // alert("Coupon Code Applied !")
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios error (HTTP error)
      const { response } = error;
      // Set the error message
      const errorMessage = response.data.message;
      alert(errorMessage);
      // Log the error message as a string
    } else {
      // Network error (e.g., no internet connection)
      // alert("Something went wrong");
    }
  }
};

// getAllSubCategory

export const getAllSubCategory = async (categoriesId) => {
  function getToken() {
    return document.cookie.replace(
      /(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/,
      "$1"
    );
  }

  // Retrieve token
  const token = getToken();
  try {
    const headers = {
      "x-auth-token": token, // Pass the token in the header
      "Content-Type": "application/json", // Set content type to JSON
    };
    const response = await axios.get(
      `${BASE_URL}/admin/categoryAndSubCategory/getAllSubCategory/${categoriesId}`,
      {
        headers,
      }
    );
    // console.log(response, "Response from axios");

    // Directly return the data from axios response
    return response.data;
  } catch (error) {
    console.error("Error in getAllCategory function:", error);
  }
};

export const getAllSubCategoryProduct = async (category, subcategory = "") => {
  function getToken() {
    return document.cookie.replace(
      /(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/,
      "$1"
    );
  }

  // Retrieve token
  const token = getToken();
  const headers = {
    "x-admin-token": token,
    "Content-Type": "application/json",
  };

  try {
    // Encode parameters
    const encodedCategory = encodeURIComponent(category);
    let url = `${BASE_URL}/admin/product/getAllproduct?category=${encodedCategory}`;
    if (subcategory) {
      const encodedSubCategory = encodeURIComponent(subcategory);
      url += `&sub_category=${encodedSubCategory}`;
    }

    // Decode URL to preserve special characters and spaces
    const decodedUrl = decodeURIComponent(url);

    // Making the request
    const response = await axios.get(decodedUrl, { headers });
    // console.log(response, "Response from axios");

    // Return the response data
    return response.data;
  } catch (error) {
    console.error("Error in getAllSubCategoryProduct function:", error);
    throw error; // It's often a good idea to re-throw the error so the caller can handle it.
  }
};

// getAllBanner

export const getAllBanner = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/public/getAllBanner`, {});
    // console.log(response, "Response from axios");

    // Directly return the data from axios response
    return response.data;
  } catch (error) {
    console.error("Error in getAllCategory function:", error);
  }
};

export const getTimeSlot = async () => {
  try {
    const response = await axios.get(
      `https://www.backend.zuluresh.com/public/getTimeSlot`
    );
    // Directly return the data from axios response
    return response.data;
  } catch (error) {
    console.error("Error in getTimeSlot function:", error);
  }
};

export const getAllAddress = async () => {
  // Function to retrieve token from cookies
  function getToken() {
    return document.cookie.replace(
      /(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/,
      "$1"
    );
  }

  // Retrieve token
  const token = getToken();
  try {
    const headers = {
      "x-auth-token": token, // Pass the token in the header
      "Content-Type": "application/json", // Set content type to JSON
    };
    const response = await axios.get(`${BASE_URL}/user/address/allAddress`, {
      headers,
    });
    // Directly return the data from axios response
    return response.data;
  } catch (error) {
    console.error("Error in getTimeSlot function:", error);
  }
};

export const deleteAddress = async (id) => {
  // Function to retrieve token from cookies
  function getToken() {
    return document.cookie.replace(
      /(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/,
      "$1"
    );
  }

  // Retrieve token
  const token = getToken();
  try {
    const headers = {
      "x-auth-token": token, // Pass the token in the header
      "Content-Type": "application/json", // Set content type to JSON
    };
    const response = await axios.delete(
      `${BASE_URL}/user/address/deleteAddress/${id}`,
      { headers }
    );
    // Directly return the data from axios response
    // alert("address removed  successfully")
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios error (HTTP error)
      const { response } = error;
      // Set the error message
      const errorMessage = response.data.message;
      // alert(errorMessage);
      // Log the error message as a string
      console.error("Axios Error:", errorMessage);
    } else {
      // Network error (e.g., no internet connection)
      // alert("Something went wrong");
      console.error("Network Error:", error.message);
    }
  }
};

//Add to Cart

export const AddtoCart = async (id) => {
  // Function to retrieve token from cookies
  function getToken() {
    return document.cookie.replace(
      /(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/,
      "$1"
    );
  }

  // Retrieve token
  const token = getToken();

  try {
    const headers = {
      "x-auth-token": token, // Pass the token in the header
      "Content-Type": "application/json", // Set content type to JSON
    };
    const response = await axios.post(
      `${BASE_URL}/user/cart/addToCart`,

      {
        productId: id,
      },

      { headers }
    );

    const { status, message, data } = response.data;

    // Handle response data as needed
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios error (HTTP error)
      const { response } = error;
      // Set the error message
      const errorMessage = response.data.message;
      // alert(errorMessage);
      // Log the error message as a string
      console.error("Axios Error:", errorMessage);
    } else {
      // Network error (e.g., no internet connection)
      // alert("Something went wrong");
      console.error("Network Error:", error.message);
    }
  }
};

//remove to Cart

export const removetoCart = async (id) => {
  // Function to retrieve token from cookies
  function getToken() {
    return document.cookie.replace(
      /(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/,
      "$1"
    );
  }

  // Retrieve token
  const token = getToken();

  try {
    const headers = {
      "x-auth-token": token, // Pass the token in the header
      "Content-Type": "application/json", // Set content type to JSON
    };
    const response = await axios.put(
      `${BASE_URL}/user/cart/updateCart`,

      {
        productId: id,
        removeProduct: "1",
      },

      { headers }
    );

    const { status, message, data } = response.data;

    // Handle response data as needed
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios error (HTTP error)
      const { response } = error;
      // Set the error message
      const errorMessage = response.data.message;
      // alert(errorMessage);
      // Log the error message as a string
      console.error("Axios Error:", errorMessage);
    } else {
      // Network error (e.g., no internet connection)
      // alert("Something went wrong");
      console.error("Network Error:", error.message);
    }
  }
};

//removeProductfromCart

export const removeProductfromCart = async (id) => {
  // Function to retrieve token from cookies
  function getToken() {
    return document.cookie.replace(
      /(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/,
      "$1"
    );
  }

  // Retrieve token
  const token = getToken();

  try {
    const headers = {
      "x-auth-token": token, // Pass the token in the header
      "Content-Type": "application/json", // Set content type to JSON
    };
    const response = await axios.put(
      `${BASE_URL}/user/cart/updateCart`,

      {
        productId: id,
        removeProduct: "0",
      },

      { headers }
    );

    const { status, message, data } = response.data;

    // Handle response data as needed
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios error (HTTP error)
      const { response } = error;
      // Set the error message
      const errorMessage = response.data.message;
      // alert(errorMessage);
      // Log the error message as a string
      console.error("Axios Error:", errorMessage);
    } else {
      // Network error (e.g., no internet connection)
      // alert("Something went wrong");
      console.error("Network Error:", error.message);
    }
  }
};
