import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './ErrorPage.module.css'; // Importing the CSS module

function ErrorPage() {
  const navigate = useNavigate();

  return (
    <div className={styles.errorPage}>
      <div className={styles.errorContent}>
        <h1 className={styles.errorMessage}>404 - Page Not Found</h1>
        <button className={styles.backButton} onClick={() => navigate('/')}>
          Back to Home
        </button>
      </div>
    </div>
  );
}

export default ErrorPage;
