import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Alert,
} from "@mui/material";
import styles from "./Login.module.css";
import NavBar from "../../NavBar/NavBar";
import bg from "../../Images/Banner.webp";
import { Helmet } from "react-helmet";
import { login, verifyUser } from "../../../Api/Api";
import Footer from "../../Footer/Footer";


const Login = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [timer, setTimer] = useState(0);


  useEffect(() => {
    let interval = null;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
    } else if (timer === 0 && interval) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,10}$/.test(value)) {
      setPhoneNumber(value);
      setError("");
    } else {
      setError("Please enter a valid 10-digit phone number");
    }
  };

  const handleSendOtp = async () => {
    if (phoneNumber.length === 10) {
      try {
        await login(phoneNumber);
        setIsOtpSent(true);
        setTimer(120); // Set timer for 2 minutes
        // alert("OTP sent to " + phoneNumber);
      } catch (error) {
        setError("Failed to send OTP. Please try again.");
      }
    } else {
      setError("Please enter a valid 10-digit phone number");
    }
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleVerifyOtp = async () => {
    const checkout_Status = sessionStorage.getItem("checkout_Status")
    try {
      const result = await verifyUser(otp,phoneNumber);
      if (result.success) {
        setIsOtpVerified(true);
        // alert("OTP verified successfully!");
        if(checkout_Status){
          window.location.href = "/checkout";
        }else{
          window.location.href = "/";
        }
      } else {
        setError(result.message);
      }
    } catch (error) {
      setError("Failed to verify OTP. Please try again.");
    }
  };

  const handleResendOtp = async() => {
    if (timer === 0) {
      setOtp("");
      setTimer(120); // Reset timer for 2 minutes
      // alert("OTP resent to " + phoneNumber);
      
    }
    try{
      await login(phoneNumber);
    }catch(error){
      setError("Failed to resend OTP. Please try again.");
    }
  };

  const handleEditPhoneNumber = () => {
    setIsOtpSent(false);
    setOtp("");
    setTimer(0);
  };

  const handleSkip = () => {
    window.location.href = "/";
  };

  return (
    <>
          <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <title>zuluresh | Buy Fresh & High Quality Meat at Best Price on Zuluresh</title>
        <meta
          name="description"
          content="Order meat online in just a few clicks.Zuluresh offers 100% fresh & best quality Chicken, Fish/Prawns and Mutton products online."
        />
        <link rel="canonical" href="https://www.zuluresh.com/login" />
        <meta name="title" content="zuluresh" />
        <meta name="keyword" content="zuluresh | Buy Fresh & High Quality Meat at Best Price on Zuluresh" />
        {/* Schema Markup */}
        <script type="application/ld+json">
          {`
          {
            "@context": "http://schema.org",
            "@type": "WebSite",
            "name": "zuluresh",
            "url": "https://www.zuluresh.com/",
            "description": "Order meat online in just a few clicks.Zuluresh offers 100% fresh & best quality Chicken, Fish/Prawns and Mutton products online.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": "https://www.zuluresh.com/",
              "query-input": "required name=search_term_string"
            }
          }
          `}
        </script>
        {/* End Schema Markup */}
        {/* Google tag (gtag.js) */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-SJPR5MYB8S"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-SJPR5MYB8S');
          `}
        </script>
        {/* Meta Pixel Code */}
        <script>
          {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '940817377734374');
          fbq('track', 'PageView');
          `}
        </script>
        <noscript>
          {`
          <img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=940817377734374&ev=PageView&noscript=1" />
          `}
        </noscript>
        {/* End Meta Pixel Code */}
      </Helmet>
      <div className={styles.main}>
        <img className={styles.bg} src={bg} alt="bg" />
        <Button className={styles.skipButton} onClick={handleSkip}>
          Skip
        </Button>
        <Container maxWidth="xs" className={styles.root}>
          <Typography variant="h5" gutterBottom>
            {isOtpSent ? "Verify OTP" : "Enter your mobile number"}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {isOtpSent ? "Enter the OTP sent to your phone" : "Login or Signup"}
          </Typography>
          <form className={styles.form} noValidate autoComplete="off">
            {isOtpSent ? (
              <>
                <TextField
                  className={styles.input}
                  label="OTP"
                  variant="outlined"
                  value={otp}
                  onChange={handleOtpChange}
                  error={!!error}
                  helperText={error}
                />
                <Button
                  className={styles.sendOtpButton}
                  variant="contained"
                  onClick={handleVerifyOtp}
                >
                  Verify OTP
                </Button>
                <Button
                  className={styles.editButton}
                  variant="text"
                  onClick={handleEditPhoneNumber}
                >
                  Edit Phone Number
                </Button>
                <Button
                  className={styles.resendButton}
                  variant="text"
                  onClick={handleResendOtp}
                  disabled={timer > 0}
                >
                  {timer > 0 ? `Resend OTP in ${timer}s` : "Resend OTP"}
                </Button>
              </>
            ) : (
              <>
                <TextField
                  className={styles.input}
                  label="Mobile Number"
                  variant="outlined"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  InputProps={{
                    startAdornment: <span>+91&#160;</span>,
                  }}
                  error={!!error}
                  helperText={error}
                />
                <Button
                  className={styles.sendOtpButton}
                  variant="contained"
                  onClick={handleSendOtp}
                >
                  Send OTP
                </Button>
              </>
            )}
          </form>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default Login;
