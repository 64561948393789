import React, { useEffect, useState } from "react";
import { Box, Modal, Typography, TextField, Button } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import axios from "axios";
import { getAllPincode } from "../../Api/Api"; // Ensure this import path is correct
import style from "./NavBar.module.css";

const BASE_URL = "https://www.backend.zuluresh.com";

const LocationModal = ({ open, onSelectLocation }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);

  useEffect(() => {
    if (open) {
      fetchLocations();
    }
  }, [open]);

  useEffect(() => {
    const selectedLocation = sessionStorage.getItem("selectedLocation");
    if (selectedLocation) {
      onSelectLocation(JSON.parse(selectedLocation));
    }
  }, []);

  const fetchLocations = async () => {
    try {
      const response = await getAllPincode();
      if (response && response.data) {
        const allLocations = response.data.reduce((acc, location) => {
          location.suggestName.forEach((suggest) => {
            acc.push({
              _id: location._id,
              areaName:
                typeof suggest === "object" ? suggest.areaName : suggest,
              district:
                typeof suggest === "object" ? suggest.district : suggest,
              state: location.state,
              pincode: location.pincode,
            });
          });
          return acc;
        }, []);
        setLocations(allLocations);
      } else {
        console.error("Failed to fetch locations");
      }
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };

  const handleSelectLocation = async (location) => {
    try {
      const pincode = location.pincode;
      const response = await axios.get(
        `${BASE_URL}/user/pincode/pincodeAvailable`,
        {
          params: { pincode },
        }
      );

      if (response.data.status) {
        const locationData = {
          pincode: location.pincode,
          state: location?.state,
          district: location?.district,
          areaName: location?.areaName,
        };
        
        sessionStorage.setItem(
          "selectedLocation",
          JSON.stringify(locationData)
        );
        onSelectLocation(response.data.data);
        window.location.reload();
      } else {
        alert("Pincode is not available in service area");
      }
    } catch (error) {
      console.error("Error checking pincode availability:", error);
    }
  };

  const handleGeolocation = () => {
    if (navigator.geolocation) {
      setLoading(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          fetchPincodeFromCoordinates(latitude, longitude);
        },
        (error) => {
          console.error("Error getting geolocation:", error);
          alert("Failed to detect location");
          setLoading(false);
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  const fetchPincodeFromCoordinates = async (latitude, longitude) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
      );
      const data = await response.json();
      if (data && data.address) {
        const postalCode = data.address.postcode || "Not found";
        checkPincodeAvailability(postalCode);
      }
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };

  const checkPincodeAvailability = async (pincode) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/user/pincode/pincodeAvailable`,
        {
          params: { pincode },
        }
      );

      if (response.data.status) {
        sessionStorage.setItem(
          "selectedLocation",
          JSON.stringify(response?.data?.data)
        );
        onSelectLocation(response.data.data);
      } else {
        alert("Pincode is not available in service area");
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const { response } = error;
        const errorMessage = response.data.message;
        alert(errorMessage);
      } else {
        alert("Something went wrong");
      }
    } finally {
      setLoading(false);
      window.location.reload();
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    const filtered = locations.filter(
      (location) =>
        location?.district
          ?.toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        location?.areaName
          ?.toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        location?.pincode.includes(e.target.value)
    );
    setFilteredLocations(filtered);
  };

  return (
    <Modal
      open={open}
      onClose={() => {}}
      BackdropProps={{ style: { pointerEvents: "none" } }} // Disable closing on backdrop click
      aria-labelledby="location-modal-title"
    >
      <Box
        className={style.modal_box}
        sx={{
          width: { xs: "90%", sm: 600 },
          bgcolor: "white",
          p: 4,
          margin: "0 auto",
          borderRadius: 2,
          boxShadow: 24,
          pointerEvents: "auto", // Enable pointer events within the modal
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            style={{ color: "#941a49", textAlign: "center" }}
            id="location-modal-title"
          >
            Select Your Location
          </Typography>
        </Box>
        <TextField
          fullWidth
          placeholder="Search location or pincode"
          value={searchTerm}
          onChange={handleSearch}
          sx={{ my: 2 }}
        />
        <Button
          variant="contained"
          fullWidth
          sx={{ mt: 2 }}
          onClick={handleGeolocation}
          disabled={loading}
          className={style.location_btn}
        >
          {loading ? "Detecting Location..." : "Use Current Location"}
        </Button>
        <div className={style.option_box}>
          {filteredLocations?.map((location) => (
            <Typography
              className={style.list_option}
              onClick={() => handleSelectLocation(location)}
            >
              <LocationOnIcon style={{ color: "#941a49" }} />
              <div className={style.area_li}>
                <h6>{location.areaName}</h6>
                <p>{`${location.district}, ${location.state}, (${location.pincode})`}</p>
              </div>
            </Typography>
          ))}
        </div>
      </Box>
    </Modal>
  );
};

export default LocationModal;
