import React, { useEffect, useState } from "react";
import style from "./SecondSection.module.css";
import product1 from "../../Images/Product1.webp";
import todaydealLogo from "../../Images/Todays deal.webp";
import { getAllCategory } from "../../../Api/Api";

function SecondSection() {
  const [allcategory, setAllCategory] = useState([]);
  useEffect(() => {
    handleAllCategory();
  }, []);
  const handleAllCategory = async () => {
    try {
      const response = await getAllCategory();
      if (response.status) {
        setAllCategory(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const formatTitleForUrl = (title) => {
    return title?.replace(/\s+/g, "-").replace(/:/g, "");
  };
  return (
    <div className={`${style.main}`}>
      <div className={style.header}>
        <h2>Shop by Categories</h2>
        <p>Fresh meats and much more</p>
      </div>
      <div className={style.seconds_box}>
        {allcategory.map((item) => (
          <a href={`category/${formatTitleForUrl(item?.categoryName)}`}>
            <img
              src={item?.categoryImg?.url}
              alt={item?.categoryName}
              title={item?.categoryName}
              loading="lazy"
              width="90px"
              height="90px"
            />
            <p>{item?.categoryName}</p>
          </a>
        ))}
      </div>
    </div>
  );
}

export default SecondSection;
